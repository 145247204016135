export const getErrorMessage = (serverErrorObject, status) => {
    if (status === 500) {
        if (serverErrorObject.error === "Invalid user") {
            return {
                errorCode: "Invalid user",
                error: "If a Unity Central account exists for this email address, an email will be sent with additional instructions.",
            };
        }
        if (serverErrorObject.error === "Invalid activationCode") {
            return {
                errorCode: "Invalid activationCode",
                error: "You have entered incorrect activationCode.",
            };
        }
        if (
            serverErrorObject.path === "Given workspace is a system workspace"
        ) {
            return {
                errorCode: "Given workspace is a system workspace",
                error: "Given workspace is a system workspace",
            };
        }
        if (
            serverErrorObject.path ===
            "Given space belongs to a system workspace"
        ) {
            return {
                errorCode: "Given space belongs to a system workspace",
                error: "Given space belongs to a system workspace",
            };
        }
        if (
            serverErrorObject.path ===
            "Given folder belongs to a system workspace"
        ) {
            return {
                errorCode: "Given folder belongs to a system workspace",
                error: "Given folder belongs to a system workspace",
            };
        }
        if (serverErrorObject.path === "Invalid Storage") {
            return {
                errorCode: "Invalid Storage",
                error: "The storage associated with this Step is not available, please update the storage.",
            };
        }
        if (serverErrorObject.path === "Internal Server Error") {
            return {
                errorCode: "Internal Server Error",
                error: "Internal Server Error",
            };
        }
        return {
            errorCode: "Something went wrong",
            error: "Server not responding, Please connect with Admin!",
        };
    }

    if (status === 400) {
        if (
            serverErrorObject.path ===
            "Invalid spacePersistence for the given spacePersistenceId"
        ) {
            return {
                errorCode:
                    "Invalid spacePersistence for the given spacePersistenceId",
                error: "Invalid Target Location.",
            };
        }
        if (
            serverErrorObject.path ===
            "Invalid Target Location - Target Location and Source Locations is same!!"
        ) {
            return {
                errorCode: "Invalid Target Location",
                error: "Target Location and Source Locations is same!!",
            };
        }
        if (serverErrorObject.path === "Invalid Target Location") {
            return {
                errorCode: "Invalid Target Location",
                error: "Invalid Target Location.",
            };
        }
        if (serverErrorObject.path === "spaceId does not exist") {
            return {
                errorCode: "spaceId does not exist",
                error: "space does not exist",
            };
        }
        if (serverErrorObject.path === "parentFolderId does not exist") {
            return {
                errorCode: "parentFolderId does not exist",
                error: "parentFolder does not exist",
            };
        }
        if (serverErrorObject.error === "Invalid Current Password") {
            return {
                errorCode: "Invalid Current Password",
                error: "Current password you have entered is incorrect.",
            };
        }
        return {
            errorCode: "Invalid request",
            error: "The information you provided seems invalid.\n Please contact UC admin.",
        };
    }

    if (status === 401) {
        if (serverErrorObject.path === "workspaceId does not exist") {
            return {
                errorCode: "workspaceId does not exist",
                error: "workspace does not exist",
            };
        }
        return {
            errorCode: "Authentication error",
            error: "The information you provided does not match our records.\n Please re-enter and try again",
        };
    }

    if (status === 404) {
        console.log(serverErrorObject);
        if (serverErrorObject.path === "Files or Folders doesn't exists") {
            return {
                errorCode: "Files or Folders doesn't exists",
                error: "Files or Folders doesn't exists",
            };
        }
        if (serverErrorObject.path === "File Not Found") {
            return {
                errorCode: "Files doesn't exists",
                error: "Files doesn't exists",
            };
        } else
            return {
                errorCode: "Resource unavailable",
                error: "This service is not available, Please contact UC admin.",
            };
    }

    if (status === 403) {
        console.log(serverErrorObject);
        if (
            serverErrorObject.path === "Given workspace is a system workspace"
        ) {
            return {
                errorCode: "Given workspace is a system workspace",
                error: "Given workspace is a system workspace",
            };
        }
        if (serverErrorObject.path === "Only Owner can share the workspace") {
            return {
                errorCode: "Only Owner can share the workspace",
                error: "Only Owner has permission to share the workspace",
            };
        }
        if (
            serverErrorObject.path ===
            "This Workspace is already shared with other user."
        ) {
            return {
                errorCode: "This Workspace is already shared with other user.",
                error: "This Workspace is already shared with other user.",
            };
        }
        if (serverErrorObject.path === "Only Owner can share the space") {
            return {
                errorCode: "Only Owner can share the space",
                error: "Only Owner has permission to share the space",
            };
        }
        if (serverErrorObject.path === "Only Owner can share the folder.") {
            return {
                errorCode: "Only Owner can share the folder.",
                error: "Only Owner has permission to share the folder.",
            };
        }
        if (
            serverErrorObject.path ===
            "This space contains items shared by other users"
        ) {
            return {
                errorCode: "This space contains items shared by other users",
                error: "This space contains items shared by other users",
            };
        }
        if (
            serverErrorObject.path ===
            "This workspace contains items shared by other users"
        ) {
            return {
                errorCode:
                    "This workspace contains items shared by other users",
                error: "This workspace contains items shared by other users",
            };
        }
        if (
            serverErrorObject.path ===
            "This folder contains items shared by other users"
        ) {
            return {
                errorCode: "This folder contains items shared by other users",
                error: "This folder contains items shared by other users",
            };
        }

        if (serverErrorObject.path === "Only Owner can share the generic") {
            return {
                errorCode: "Only Owner can share the generic",
                error: "Only Owner can share the generic",
            };
        }
        if (
            serverErrorObject.path ===
            "This space is already shared with other user."
        ) {
            return {
                errorCode: "This space is already shared with other user.",
                error: "This space is already shared with other user.",
            };
        }
        if (
            serverErrorObject.path ===
            "This is a shared generic by another user"
        ) {
            return {
                errorCode: "This space is already shared with other user.",
                error: "This operation is not allowed as this is a shared generic by another user",
            };
        }
        if (
            serverErrorObject.path ===
            "This Folder is already shared with other user."
        ) {
            return {
                errorCode: "This Folder is already shared with other user.",
                error: "This Folder is already shared with other user.",
            };
        }
        if (serverErrorObject.path === "instanceId does not exist") {
            return {
                errorCode: "instanceId does not exist",
                error: "Given instanceId does not exist",
            };
        }
        if (serverErrorObject.path === "Invalid ownerGenericId") {
            return {
                errorCode: "Invalid ownerGenericId",
                error: "Given ownerGenericId is invalid",
            };
        }
        if (
            serverErrorObject.path ===
            "Operation not allowed as this is shared folder"
        ) {
            return {
                errorCode: "Operation not allowed as this is shared folder",
                error: "This folder is shared with another user",
            };
        }
        if (
            serverErrorObject.path ===
            "Operation not allowed as this is shared space"
        ) {
            return {
                errorCode: "Operation not allowed as this is shared space",
                error: "This space is shared with another user",
            };
        }
        if (
            serverErrorObject.path ===
            "Operation not allowed as this is shared workspace"
        ) {
            return {
                errorCode: "Operation not allowed as this is shared workspace",
                error: "This workspace is shared with another user",
            };
        }
        if (serverErrorObject.path === "This step can not be split") {
            return {
                errorCode: "Operation not allowed",
                error: "Cannot create branch from this step",
            };
        }
        if (
            serverErrorObject.path ===
            "Operation not allowed as this is shared file"
        ) {
            return {
                errorCode: "Operation not allowed as this is shared file",
                error: "This file is shared with another user",
            };
        }
        if (serverErrorObject.path === "File can not be mark as a favourite") {
            return {
                errorCode: "File can not be mark as a favourite",
                error: "File can not be mark as a favourite",
            };
        } else {
            return {
                errorCode: "Resource unavailable",
                error: "Operation not allowed as this is shared resource",
            };
        }
    }
    if (status === 409) {
        if (serverErrorObject.path === "workspaceName already exists") {
            return {
                errorCode: "workspaceName already exists",
                error: "workspaceName already exists",
            };
        }
        if (serverErrorObject.path === "Folder already has this name") {
            return {
                errorCode: "Folder already has this name",
                error: "Folder already has this name",
            };
        }
        if (serverErrorObject.path === "Generic Name already exists") {
            return {
                errorCode: "Generic Name already exists",
                error: "Generic Name already exists",
            };
        }
        if (serverErrorObject.path === "Workspace saved already") {
            return {
                errorCode: "Workspace saved already",
                error: "Workspace saved already",
            };
        }
        if (
            serverErrorObject.path ===
            "This workspace children are already present in this user."
        ) {
            return {
                errorCode:
                    "This workspace children are already present in this user.",
                error: "This workspace children are already present in this user.",
            };
        }
        if (serverErrorObject.path === "Space saved already") {
            return {
                errorCode: "Space saved already",
                error: "Space saved already",
            };
        }
        if (
            serverErrorObject.path ===
            "This space children are already present in this user"
        ) {
            return {
                errorCode:
                    "This space children are already present in this user",
                error: "This space children are already present in this user",
            };
        }
        if (serverErrorObject.path === "folderName already exists") {
            return {
                errorCode: "folderName already exists",
                error: "folderName already exists",
            };
        }
        if (
            serverErrorObject.path ===
            "This folder children are already present in this user."
        ) {
            return {
                errorCode:
                    "This folder children are already present in this user.",
                error: "This folder children are already present in this user.",
            };
        }
        if (serverErrorObject.path === "Generic saved already") {
            return {
                errorCode: "Generic saved already",
                error: "Generic saved already",
            };
        }
        if (serverErrorObject.path === "spaceName already exists") {
            return {
                errorCode: "spaceName already exists",
                error: "spaceName already exists",
            };
        }
        if (
            serverErrorObject.path ===
            "File/Folder with the same name already exists at the same level"
        ) {
            return {
                errorCode:
                    "File/Folder with the same name already exists at the same level",
                error: "File/Folder with the same name already exists at the same level",
            };
        }
    }
    if (!serverErrorObject)
        return {
            errorCode: "Something went wrong",
            error: "Something went wrong, Please try again!",
        };
    const errorObject = serverErrors.find(
        (item) =>
            item.errorCode === serverErrorObject.error ||
            item.errorCode === serverErrorObject.path
    );
    return errorObject
        ? errorObject
        : {
              errorCode: "Something went wrong",
              error: "Something went wrong, Please try again!",
          };
};

const serverErrors = [
    {
        errorCode: "Operation not allowed as this is shared folder",
        error: "This folder is shared with another user",
    },
    {
        errorCode: "File Not Found",
        error: "This file is not available",
    },
    {
        errorCode: "Invalid spacePersistence for the given spacePersistenceId",
        error: "Invalid Storage Location",
    },
    {
        errorCode: "Operation not allowed as this is shared workspace",
        error: "This Workspace is shared with another user",
    },
    {
        errorCode: "Operation not allowed as this is shared space",
        error: "This space is shared with another user",
    },
    {
        errorCode: "Operation not allowed as this is shared space",
        error: "This space is shared with another user",
    },
    {
        errorCode: "Only Owner can share the generic",
        error: "Only Owner can share the generic",
    },
    {
        errorCode:
            "Operation not allowed as this workspace contains shared space",
        error: "This workspace contains shared space",
    },
    {
        errorCode: "Invalid Storage",
        error: "The storage associated with this Step is not available, please update the storage.",
    },
    {
        errorCode: "Given workspace is a system workspace",
        error: "Given workspace is a system workspace",
    },
    {
        errorCode: "Space saved already",
        error: "Space saved already",
    },

    {
        errorCode: "genericStepName exists already",
        error: "Generic StepName exists already",
    },
    {
        errorCode: "Given space belongs to a system workspace",
        error: "Given space belongs to a system workspace",
    },
    {
        errorCode: "Directory senderUser already exists",
        error: "User already exists",
    },
    {
        errorCode: "Operation not allowed as this is shared folder",
        error: "Operation not allowed as this is shared folder",
    },
    {
        errorCode: "Folder saved already",
        error: "Folder saved already",
    },
    {
        errorCode: "Incorrect User Id or Password",
        error: "You have entered incorrect userId or password",
    },
    {
        errorCode: "Invalid user",
        error: "You have entered incorrect userId",
    },
    {
        errorCode: "User is Inactive",
        error: "Entered userId is inactive, Please contact Boardwalk admin!",
    },
    {
        errorCode: "Password Expired",
        error: "Your password is expired, Please update the password",
    },
    {
        errorCode: "Account Locked Permanently",
        error: "Your account is locked permanently, Please contact Boardwalk admin!",
    },
    {
        errorCode: "Account Locked Temporarily",
        error: "Your account is locked Temporarily, Please contact Boardwalk admin!",
    },
    {
        errorCode: "Password Should be Changed",
        error: "Your password is too old, Please change the password",
    },
    {
        errorCode: "Object Exists",
        error: "This user already exists, Please try with different user",
    },
    {
        errorCode: "Invalid user",
        error: "This user is invalid, Please try with correct user",
    },
    {
        errorCode: "Inactive user",
        error: "This user is inactive, Please contact Boardwalk admin to activate the user",
    },
    {
        errorCode: "Invalid activationCode",
        error: "Activation code provided is invalid, Please enter valid code then try again",
    },
    {
        errorCode: "New password should not match with last n passwords",
        error: "Password provide should not match with old password, Please enter new password and then try again",
    },
    {
        errorCode: "Incorrect username",
        error: "UserId provided is incorrect, Please enter correct user and then try again",
    },
    {
        errorCode: "Error while updating password",
        error: "There was error while updating password, Please try again",
    },
    {
        errorCode: "Workspace already exists",
        error: "Cannot create this workspace as Workspace with this name already present",
    },
    {
        errorCode: "workspaceName already exists",
        error: "Cannot update this workspace as Workspace with this name already present",
    },
    {
        errorCode: "This is a shared generic",
        error: "This operation is not allowed as the Generic is shared",
    },
    {
        errorCode: "This is a shared generic by another user",
        error: "This operation is not allowed as this is a shared generic by another user",
    },
    {
        errorCode: "Invalid Cuboid Ids",
        error: "Cannot create space for this request",
    },
    {
        errorCode: "Space with the same name already exists in the workspace",
        error: "Space Name already exists",
    },
    {
        errorCode: "instanceName already exists",
        error: "InstanceName already exists",
    },
    {
        errorCode:
            "Workspace with the same name already exist in active workspaces",
        error: "Workspace name already exist",
    },
    {
        errorCode: "Folder with the same name already exists at the same level",
        error: "Folder name already exist",
    },
    {
        errorCode: "folderName already exists",
        error: "Folder with the same name already exists at the same level",
    },
    {
        errorCode: "File with the same name already exists at the same level",
        error: "File name already exist",
    },
    {
        errorCode: "spaceName already exists",
        error: "The Space with this name already present, if not present in this workspace please check archived items",
    },
    {
        errorCode: "workspaceId does not exist",
        error: "Workspace does not exist",
    },
    {
        errorCode: "Generic shared already",
        error: "Generic shared already",
    },
    {
        errorCode: "Some of the spaceId does not exist",
        error: "Some of the spaces you are trying to archive does not exists",
    },
    {
        errorCode: "objectName already exists",
        error: "File you are trying to add is already present in this space",
    },
    {
        errorCode: "folderName already exists",
        error: "Folder you are trying to add is already present in this space",
    },
    {
        errorCode: "workspaceid does not exist",
        error: "Workspace does not exist",
    },
    {
        errorCode: "spaceid does not exist",
        error: "Space does not exist in this workspace",
    },
    {
        errorCode: "folder Id does not exist",
        error: "Folder does not exist in this space",
    },
    {
        errorCode: "instanceBlockRegistry already exists",
        error: "File you are trying to add is already present in this space",
    },
    {
        errorCode: "Invalid alertRegistryId",
        error: "This operation can't be performed",
    },
    {
        errorCode: "Invalid alertRegistryTblId",
        error: "This operation can't be performed",
    },
    {
        errorCode: "folderId does not exist",
        error: "Folder does not exist.",
    },
    {
        errorCode: "spaceId does not exist",
        error: "Space does not exist.",
    },
    {
        errorCode: "workspaceId does not exist",
        error: "Workspace does not exist.",
    },
    {
        errorCode: "User Doesn't exist",
        error: "User Doesn't exist",
    },
    {
        errorCode: "Unsuccessful connection",
        error: "Unsuccessful connection",
    },
    {
        errorCode: "Invalid File Type",
        error: "This file type is not supported yet",
    },
    {
        errorCode: "No Integration Details Available",
        error: "No Integration Found,Please Integrate Your Account First",
    },
    {
        errorCode: "Internal Server Error",
        error: "Internal Server Error",
    },
    {
        errorCode: "No Filters defined yet",
        error: "No Filters defined yet",
    },
    {
        errorCode: "Refresh Token is Expired",
        error: "Refresh token is expired.Please authonticate your account from Integrations",
    },
    {
        errorCode:
            "Filter with different Filter Name but same values already exist",
        error: "Filter data is already Exists.Please try with different filter data",
    },
    {
        errorCode: "folderId does not exist",
        error: "Folder does not exist.",
    },
    {
        errorCode: "spaceId does not exist",
        error: "Space does not exist.",
    },
    {
        errorCode: "workspaceId does not exist",
        error: "Workspace does not exist.",
    },
    {
        errorCode: "Generic  already exists",
        error: "Generic name already exists.",
    },
    {
        errorCode: "Operation not allowed as this is shared space",
        error: "This operation is not allowed as this is shared Space",
    },
    {
        errorCode: "Operation not allowed as this is shared folder",
        error: "This operation is not allowed as this is shared Folder",
    },
    {
        errorCode:
            "Operation not allowed as the contents of this folder are shared",
        error: "This operation is not allowed as the contents of this Folder are shared",
    },
    {
        errorCode:
            "Operation not allowed as the contents of this space are shared",
        error: "This operation is not allowed as the contents of this Space are shared",
    },
    {
        errorCode: "Folder already shared with this user",
        error: "This operation is not allowed as the Folder already shared with this user",
    },
    {
        errorCode: "Space already shared with this user",
        error: "This operation is not allowed as the Space already shared with this user",
    },
    {
        errorCode: "Space shared already",
        error: "This operation is not allowed as the Space is already shared",
    },
    {
        errorCode: "Folder shared already",
        error: "This operation is not allowed as the Folder is already shared",
    },
    {
        errorCode:
            "Operation not allowed as the contents of a space are shared",
        error: "This operation is not allowed as the contents of the space is shared",
    },
    {
        errorCode: "This is a shared space by another user",
        error: "This operation is not allowed as the space shared by another user",
    },
    {
        errorCode: "This is a shared folder by another user",
        error: "This operation is not allowed as the folder shared by another user",
    },
    {
        errorCode: "genericName already exists",
        error: "This flow is already present",
    },
    {
        errorCode: "AuthenticationFailure:Incorrect User Id or Password",
        error: "Incorrect User Id or Password",
    },
    {
        errorCode: "AuthenticationFailure:User is Inactive",
        error: "User is Inactive",
    },
    {
        errorCode: "AuthenticationFailure:Password Expired",
        error: "Password Expired",
    },
    {
        errorCode: "AuthenticationFailure:Account Locked Permanently",
        error: "Account Locked Permanently",
    },
    {
        errorCode: "AuthenticationFailure:Account Locked Temporarily",
        error: "Account Locked Temporarily",
    },
    {
        errorCode: "AuthenticationFailure:Password Should be Changed",
        error: "Password Should be Changed",
    },
    {
        errorCode: "Invalid workspaceId for the given objectId",
        error: "Information provided for this workspace is invalid",
    },
    {
        errorCode: "Invalid space for the given objectId",
        error: "Information provided for this space is invalid",
    },
    {
        errorCode: "Invalid spacePersistence for the given objectId",
        error: "Information provided for this space is invalid",
    },
    {
        errorCode: "AuthenticationFailure:Unknown Error occurred",
        error: "Unknown error occured in authentication",
    },
    {
        errorCode: "Invalid samlToken",
        error: "Invalid saml token is provided",
    },
    {
        errorCode: "Invalid authenticationType",
        error: "Invalid authentication type",
    },
    {
        errorCode: "AuthenticationException",
        error: "Exception occured in authentication",
    },
    {
        errorCode: "Invalid contactsTblId",
        error: "Invalid contactsTblId is provided",
    },
    {
        errorCode: "Invalid chatRegistryTblId",
        error: "Invalid chatRegistryTblId is provided",
    },
    {
        errorCode: "participantUserIds can not contain duplicate values",
        error: "Participants should not have duplicate values",
    },
    {
        errorCode: "Please Contact Boardwalk System Administrator",
        error: "Please Contact Boardwalk System Administrator",
    },
    {
        errorCode: "genericId does not exist",
        error: "Given generic does not exist",
    },
    {
        errorCode: "Invalid Generic Document Chain Table Ids",
        error: "Invalid Generic Document Chain Table Ids",
    },
    {
        errorCode: "Invalid Link definition Table Ids",
        error: "Invalid Link definition Table Ids",
    },
    {
        errorCode: "objectId does not exist",
        error: "Given objectId does not exist",
    },
    {
        errorCode:
            "File/Folder with the same name already exists at the same level",
        error: "File/Folder with the same name already exists at the same level",
    },
    {
        errorCode: "UnSupported ObjectType",
        error: "UnSupported ObjectType",
    },
    {
        errorCode: "instanceId does not exist",
        error: "Given instanceId does not exist",
    },
    {
        errorCode: "exceptionRegistryId not found",
        error: "exceptionRegistryId not found",
    },
    {
        errorCode: "Invalid instanceStepId",
        error: "Invalid instanceStepId",
    },
    {
        errorCode: "instanceName already exists",
        error: "Instance name already exists",
    },
    {
        errorCode: "Unsupported value for status",
        error: "Unsupported value for status",
    },
    {
        errorCode: "Invalid Workspace Cuboid Ids",
        error: "Invalid Workspace Cuboid Ids",
    },
    {
        errorCode: "Incorrect parentInstanceStepId",
        error: "Incorrect parentInstanceStepId",
    },
    {
        errorCode: "Invalid parentInstanceStepId",
        error: "Invalid parentInstanceStepId",
    },
    {
        errorCode: "parentInstanceBlockId does not exist",
        error: "parentInstanceBlockId does not exist",
    },
    {
        errorCode: "branchId can not updated",
        error: "Branch id can not be updated",
    },
    {
        errorCode: "branchName can not updated",
        error: "Branch name can not be updated",
    },
    {
        errorCode: "Invalid File Size",
        error: "File size should not be more than 25 MB",
    },
    {
        errorCode: "Invalid documentId",
        error: "Given documentId is invalid",
    },
    {
        errorCode: "This step can not be split",
        error: "This step can not be splitted",
    },
    {
        errorCode: "Invalid Source instanceStepId",
        error: "Given source instanceStepId is invalid",
    },
    {
        errorCode: "Invalid instanceStepRegistryTarget",
        error: "Given instanceStepRegistryTarget is invalid",
    },
    {
        errorCode: "Invalid BranchId",
        error: "Given branchId is invalid",
    },
    {
        errorCode: "Can not delete this branch, because it has document!!",
        error: "Branch can not be deleted as it is having document",
    },
    {
        errorCode: "Invalid form data",
        error: "Given form data is invalid",
    },
    {
        errorCode: "uploadedInputStream or fileDetail",
        error: "Given form data is invalid",
    },
    {
        errorCode: "Invalid documentRepository",
        error: "Given documentRepository is invalid",
    },
    {
        errorCode: "Invalid Document Whiteboard Ids",
        error: "Given document whiteboard ids are invalid",
    },
    {
        errorCode: "Invalid headerRegistryTblId",
        error: "Given headerRegistryTblId is invalid",
    },
    {
        errorCode: "Invalid Instance Registry Table Ids",
        error: "Given instance registry table ids are invalid",
    },
    {
        errorCode: "Please try again with valid data",
        error: "Please try again with valid data",
    },
    {
        errorCode:
            "Please give necessary permissions in order to integrate Gmail with Unity Central...",
        error: "Please give necessary permissions in order to integrate Gmail with Unity Central...",
    },
    {
        errorCode: "Invalid mailDataTblId",
        error: "Given mailDataTblId is invalid",
    },
    {
        errorCode: "Operation not allowed as the contents of a space is shared",
        error: "Operation not allowed as the contents of a space is shared",
    },
    {
        errorCode: "Invalid targetFolderId",
        error: "Given targetFolderId is invalid",
    },
    {
        errorCode: "Invalid spacePersistenceFile",
        error: "Given spacePersistenceFile is invalid",
    },
    {
        errorCode: "Invalid documentDetails",
        error: "Given documentDetails is invalid",
    },
    {
        errorCode: "Invalid Row Id",
        error: "Given row id is invalid",
    },
    {
        errorCode: "Invalid rippleRegistryTblId",
        error: "Given rippleRegistryTblId is invalid",
    },
    {
        errorCode: "Invalid rippleRegistryId",
        error: "Given rippleRegistryId is invalid",
    },
    {
        errorCode: "This is system generated Ripple Log",
        error: "This is system generated Ripple Log",
    },
    {
        errorCode: "Invalid workspaceTblIdsOwner",
        error: "Given workspaceTblIdsOwner is invalid",
    },
    {
        errorCode: "Invalid ownerSpaceId",
        error: "Given ownerSpaceId is invalid",
    },
    {
        errorCode: "Invalid workspaceTblIdsShared",
        error: "Given workspaceTblIdsShared is invalid",
    },
    {
        errorCode: "Invalid ownerFolderId",
        error: "Given ownerFolderId is invalid",
    },
    {
        errorCode: "Given id is not a folderId",
        error: "Given id is not a folderId",
    },
    {
        errorCode: "Invalid genericTblIdsOwner",
        error: "Given genericTblIdsOwner is invalid",
    },
    {
        errorCode: "Invalid ownerGenericId",
        error: "Given ownerGenericId is invalid",
    },
    {
        errorCode: "Invalid genericTblIdsShared",
        error: "Given genericTblIdsShared is invalid",
    },
    {
        errorCode: "Invalid spaceSharingObject",
        error: "Given spaceSharingObject is invalid",
    },
    {
        errorCode: "parentFolderId does not exist",
        error: "Parent folder id does not exist",
    },
    {
        errorCode: "Invalid folderSharingObject",
        error: "Given folderSharingObject is invalid",
    },
    {
        errorCode: "Filter Name already exists",
        error: "Filter Name already exists",
    },
    {
        errorCode: "Invalid genericDocumentChainTblIdsOwner",
        error: "Given genericDocumentChainTblIdsOwner is invalid",
    },
    {
        errorCode: "Generic Display Name already exists",
        error: "Generic display name already exists, please enter different generic display name.",
    },
    {
        errorCode: "Invalid genericSharingObject",
        error: "Given genericSharingObject is invalid",
    },
    {
        errorCode: "Generic saved already",
        error: "Generic saved already",
    },
    {
        errorCode: "Failed to create new Generic",
        error: "Failed to create new Generic",
    },
    {
        errorCode: "Operation Failed",
        error: "Operation you are trying to perform is failed.",
    },
    {
        errorCode: "Invalid Owner Document Chain Table Ids",
        error: "Given owner document chain table ids are invalid",
    },
    {
        errorCode: "genericName does not exist",
        error: "Generic name does not exist",
    },
    {
        errorCode: "Invalid InstanceBlockRegistry or DocumentId",
        error: "Given InstanceBlockRegistry or DocumentId is invalid",
    },
    {
        errorCode: "some workspaceId does not exist",
        error: "Some of the workspaces you are trying to archive does not exists",
    },
    {
        errorCode: "User Exists Already",
        error: "User is already exists",
    },
    {
        errorCode: "Invalid activation code",
        error: "Activation code entered is invalid",
    },
    {
        errorCode:
            "User account can not be cloned because sourceUserId doesn't exist",
        error: "Activation code entered is invalid",
    },
    {
        errorCode:
            "Can not create User. Please contact Boardwalk Administrator",
        error: "Can not create User. Please contact Boardwalk Administrator",
    },
    {
        errorCode: "Invalid Target Location",
        error: "Invalid Target Location",
    },
    {
        errorCode: "Given workspace is a system workspace",
        error: "This operation is not allowed on a system workspace",
    },
];

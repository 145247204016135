import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function Breadcrumb({
    breadcrumbData,
    handleBreadcrumbClick,
    flow,
    email,
}) {
    return (
        <div role="presentation">
            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small" />}
            >
                {breadcrumbData.map((item, index) => (
                    <div
                        key={item.id}
                        title={item.name}
                        className="workspace-actions"
                        style={{
                            gap: "8px",
                            cursor: "pointer",
                        }}
                        onClick={(event) => handleBreadcrumbClick(event, item)}
                    >
                        {!index && item.name === "Flow" ? (
                            <img
                                src="./images/flowWksp.svg"
                                alt=""
                                className="action-icon"
                            />
                        ) : !index && item.name === "Emails" ? (
                            <img
                                src="./images/email.svg"
                                alt=""
                                className="action-icon"
                            />
                        ) : !index ? (
                            <img
                                src="./images/workspace-icon-color.svg"
                                alt=""
                                className="action-icon"
                            />
                        ) : index === 1 && flow === true ? (
                            <img
                                src="./images/smallInst.svg"
                                alt=""
                                className="action-icon"
                            />
                        ) : index === 1 && email === true ? (
                            <img
                                src="./images/inbox.svg"
                                alt=""
                                className="action-icon"
                            />
                        ) : index === 3 &&
                          email === true &&
                          item?.type?.toUpperCase() === "EMAIL" ? (
                            <img
                                src="./images/email.svg"
                                alt=""
                                className="action-icon"
                            />
                        ) : index === 3 && email === true ? (
                            <img
                                src="./images/paperclip.svg"
                                alt=""
                                className="action-icon"
                            />
                        ) : index > 1 && email === true ? (
                            <img
                                src="./images/email.svg"
                                alt=""
                                className="action-icon"
                            />
                        ) : index === 1 ? (
                            <img
                                src="./images/spaceIcon.svg"
                                alt=""
                                className="action-icon"
                            />
                        ) : item.type === "email" ? (
                            <img
                                src="./images/email.svg"
                                alt=""
                                className="action-icon"
                            />
                        ) : (
                            <img
                                src="./images/FOLDER.svg"
                                alt=""
                                className="action-icon"
                            />
                        )}
                        <span className="action-label textEllipsis">
                            {item.name}
                        </span>
                    </div>
                ))}
            </Breadcrumbs>
        </div>
    );
}

import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "../../../../../stylized/CustomDropdown";
import CustomDateDropdown from "../../../../../stylized/CustomDateDropdown";
import {
    fetchFilterData,
    setAggregatedReportData,
    setSelectedGenericDropdownValuesAggrReport,
} from "../../../../../../actions/DashboardActions";
import { getDateByRange } from "../../../../../../bae-client/Utils";
import Loader from "../../../../../stylized/Loader";
import http from "../../../../../../bae-client/HTTP";
import CustomTable from "./table";
import "./style.css";
import CustomSearchDropdown from "../../../../../stylized/CustomDropDown/index";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        // maxWidth: "9rem",
        maxWidth: "12rem",
        borderRadius: "6px !important",
        display: "inline-flex",
        flexDirection: "column",
        position: "relative",
        minWidth: "0px",
        padding: "0px",
        margin: "8px",
        border: "0px",
        verticalAlign: "top",
        width: "100%",
    },
    dateRoot: {},
});

const AggrDashboard = () => {
    const classes = useStyles();
    const [modelList, setModelList] = useState([]);
    const [dateErr, setDateErr] = useState(false);
    const filterRawData = useSelector((state) => state.DashboardDCFilterData);
    const selectedGenericDropdownValues = useSelector(
        (state) => state.selectedGenericDropdownValuesAggrReport
    );
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedTimeState, setSelectedTimeState] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [
        selectedGenericDropdownLocalValue,
        setSelectedGenericDropdownLocalValue,
    ] = useState([]);
    const genericResponse = useSelector((state) => state.aggregatedReportData);
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(fetchFilterData());
        setSelectedGenericDropdownLocalValue(selectedGenericDropdownValues);
    }, []);

    useEffect(() => {
        if (filterRawData) {
            renderModelList();
        }
        const genericIdSet = new Set(
            filterRawData.map((item) => item.genericRegistry.genericId)
        );
        const arr = [];
        selectedGenericDropdownValues.forEach((ele) => {
            if (genericIdSet.has(ele.id)) {
                const matchingItem = filterRawData.find(
                    (item) => item.genericRegistry.genericId === ele.id
                );
                arr.push({
                    ...ele,
                    value: matchingItem.genericRegistry.genericDisplayName,
                    text: matchingItem.genericRegistry.genericDisplayName,
                });
            }
        });
        setSelectedGenericDropdownLocalValue(arr);
    }, [filterRawData]);

    const renderModelList = () => {
        let itemList = [];
        filterRawData.forEach((filterItem) => {
            itemList.push({
                text: filterItem.genericRegistry.genericDisplayName,
                value: filterItem.genericRegistry.genericDisplayName,
                id: filterItem.genericRegistry.genericId,
            });
        });
        itemList.sort((a, b) => a.text.localeCompare(b.text));
        setModelList(itemList);
    };

    const handleGenericDDSelect = (index, values) => {
        setSelectedGenericDropdownLocalValue(values);
    };

    const setSelectedTimeFilterData = (data) => {
        setSelectedTimeState(data);
    };

    const handleSubmitFilter = () => {
        let genericIds = getGenericIdsByIndexes();
        let selectedStartDate = "";
        let selectedLastDate = "";
        if (selectedTimeState.selectedTimeType === "Last 90 days") {
            let endDate = new Date().toDateString();
            let startDate = new Date(
                new Date(endDate).getTime() - 7776000000
            ).toDateString();
            selectedStartDate = formatDate(startDate) + " 00:00:00.000";
            selectedLastDate = formatDate(endDate) + " 23:59:59.999";
        } else if (selectedTimeState.selectedTimeType === "Last Year") {
            let endDate = new Date().toDateString();
            let startDate = new Date(
                new Date(endDate).getTime() - 31556952000
            ).toDateString();
            selectedStartDate = formatDate(startDate) + " 00:00:00.000";
            selectedLastDate = formatDate(endDate) + " 23:59:59.999";
        } else if (selectedTimeState.selectedTimeType === "Last 30 days") {
            let endDate = new Date().toDateString();
            let startDate = new Date(
                new Date(endDate).getTime() - 2592000000
            ).toDateString();
            selectedStartDate = formatDate(startDate) + " 00:00:00.000";
            selectedLastDate = formatDate(endDate) + " 23:59:59.999";
        } else if (selectedTimeState.selectedTimeType === "Previous month") {
            selectedStartDate =
                getDateByRange("PrevMonthStartDate") + " 00:00:00.000";
            selectedLastDate =
                getDateByRange("PrevMonthLastDate") + " 23:59:59.999";
        } else if (selectedTimeState.selectedTimeType === "Last 14 days") {
            let endDate = new Date().toDateString();
            let startDate = new Date(
                new Date(endDate).getTime() - 1209600000
            ).toDateString();
            selectedStartDate = formatDate(startDate) + " 00:00:00.000";
            selectedLastDate = formatDate(endDate) + " 23:59:59.999";
        } else if (selectedTimeState.selectedTimeType === "Last 7 days") {
            let endDate = new Date().toDateString();
            let startDate = new Date(
                new Date(endDate).getTime() - 604800000
            ).toDateString();
            selectedStartDate = formatDate(startDate) + " 00:00:00.000";
            selectedLastDate = formatDate(endDate) + " 23:59:59.999";
        } else {
            selectedStartDate = selectedTimeState.startDate
                ? selectedTimeState.startDate + " 00:00:00.000"
                : "";

            selectedLastDate = selectedTimeState.endDate
                ? selectedTimeState.endDate + " 23:59:59.999"
                : "";
        }

        let dataObj = {
            genericIds: genericIds,
            startDate: selectedStartDate,
            endDate: selectedLastDate,
        };
        getDataFromServer(dataObj);
    };

    const getDataFromServer = (filterData) => {
        setShowLoader(true);
        http.sendRestRequest(
            "documentChain/aggregatedReport",
            "POST",
            filterData
        )
            .then((response) => {
                setShowLoader(false);
                const genericsArray = Object.entries(response).map((key) => ({
                    [key[0]]: key[1],
                }));
                dispatch(setAggregatedReportData(genericsArray));
                dispatch(
                    setSelectedGenericDropdownValuesAggrReport(
                        selectedGenericDropdownLocalValue
                    )
                );
            })
            .catch((error) => {
                setShowLoader(false);
            });
    };

    const getAggregatedRow = () => {
        let totalDocs = 0;
        let totalInitiated = 0;
        let totalDelayed = 0;
        let totalCompleted = 0;
        let totalProactiveAlerts = 0;
        genericResponse.map((item) => {
            let reportObj = Object.entries(item)[0][1];
            totalDocs = totalDocs + reportObj.totalDocuments;
            totalInitiated = totalInitiated + reportObj.instancesInitiated;
            totalDelayed = totalDelayed + reportObj.instancesDelayed;
            totalCompleted = totalCompleted + reportObj.instancesCompleted;
            totalProactiveAlerts =
                totalProactiveAlerts + reportObj.totalProactiveAlerts;
        });
        return (
            <div
                style={{
                    flexBasis: "10%",
                    marginTop: "12px",
                    boxShadow: "1px 1px 20px 4px rgb(0 0 0 / 10%)",
                    borderRadius: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}
            >
                <h4 style={{ marginBottom: "0px", paddingLeft: "12px" }}>
                    Summary
                </h4>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                    }}
                >
                    <div className="measure-block">
                        <div style={{ padding: "8px" }}>
                            <img
                                src="./images/Flow/total initiated.svg"
                                alt="total docs"
                            />
                        </div>
                        <div className="summary-blocks">
                            <span>Instances Intitiated</span>
                            <span className="num">
                                {totalInitiated > 0 ? totalInitiated : 0}
                            </span>
                        </div>
                    </div>

                    <div className="measure-block">
                        <div style={{ padding: "8px" }}>
                            <img
                                src="./images/Flow/total completed.svg"
                                alt="total completed"
                            />
                        </div>
                        <div className="summary-blocks">
                            <span>Instances Completed</span>
                            <span className="num">
                                {totalCompleted > 0 ? totalCompleted : 0}
                            </span>
                        </div>
                    </div>
                    <div className="measure-block">
                        <div style={{ padding: "8px" }}>
                            <img
                                src="./images/Flow/total on time.svg"
                                alt="total on time"
                            />
                        </div>
                        <div className="summary-blocks">
                            <span>Instances On Time</span>
                            <span className="num">
                                {totalInitiated - totalDelayed > 0
                                    ? totalInitiated - totalDelayed
                                    : 0}
                            </span>
                        </div>
                    </div>
                    <div className="measure-block">
                        <div style={{ padding: "8px" }}>
                            <img
                                src="./images/Flow/total delayed flows.svg"
                                alt="total delayed flows"
                            />
                        </div>
                        <div className="summary-blocks">
                            <span>Instances Delayed</span>
                            <span className="num">
                                {totalDelayed > 0 ? totalDelayed : 0}
                            </span>
                        </div>
                    </div>

                    <div className="measure-block">
                        <div style={{ padding: "8px" }}>
                            <img
                                src="./images/Flow/total docs.svg"
                                alt="total docs"
                            />
                        </div>
                        <div className="summary-blocks">
                            <span>Documents</span>
                            <span className="num">
                                {totalDocs > 0 ? totalDocs : 0}
                            </span>
                        </div>
                    </div>
                    <div className="measure-block">
                        <div style={{ padding: "8px" }}>
                            <img
                                src="./images/Flow/total proactive.svg"
                                alt="total docs"
                            />
                        </div>
                        <div className="summary-blocks">
                            <span>Proactive Alerts</span>
                            <span className="num">
                                {totalProactiveAlerts > 0
                                    ? totalProactiveAlerts
                                    : 0}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    function formatDate(date) {
        let d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
    }

    const getGenericIdsByIndexes = () => {
        if (
            selectedGenericDropdownLocalValue !== null &&
            selectedGenericDropdownLocalValue !== undefined &&
            selectedGenericDropdownLocalValue.length
        ) {
            return selectedGenericDropdownLocalValue.map((item) => item.id);
        } else {
            return [];
        }
    };

    return (
        <div
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
            <div className="row" style={{ padding: "10px", width: "50%" }}>
                <CustomSearchDropdown
                    items={modelList}
                    label={"Generics"}
                    id={"generics-dropdown-btn"}
                    idInfo={"generics"}
                    multiSelect={true}
                    isSearchable={true}
                    isChipView={false}
                    handleSelection={handleGenericDDSelect}
                    selection={selectedGenericDropdownLocalValue}
                    customClassName={classes.root}
                ></CustomSearchDropdown>
                <CustomDateDropdown
                    setSelectedTimeFilter={setSelectedTimeFilterData}
                    dateErr={dateErr}
                    setDateErr={setDateErr}
                ></CustomDateDropdown>
                <div className="doCenter p-0" style={{ float: "right" }}>
                    <button
                        className={"btn share modalButton"}
                        type="button"
                        disabled={
                            selectedGenericDropdownLocalValue.length === 0
                                ? true
                                : false
                        }
                        id={"viewResults"}
                        style={{
                            minWidth: "9rem",
                            height: "38px",
                            margin: "0rem",
                            marginLeft: "10px",
                            background: "#0798EA",
                        }}
                        title="View Results"
                        value={"View Results"}
                        onClick={handleSubmitFilter || null}
                    >
                        View Results
                    </button>
                </div>
            </div>
            {genericResponse.length !== 0 && (
                <div
                    className="invisibleScroller"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px",
                        height: "80vh",
                    }}
                >
                    {genericResponse.map((item, index) => {
                        let reportObj = Object.entries(item)[0][1];
                        const reportProperties = [
                            {
                                iconSrc: "./images/fileTypeIcon/flowPlay.svg",
                                altText: "Document initiated",
                                label: "Instances Initiated",
                                value: reportObj.instancesInitiated,
                            },
                            {
                                iconSrc: "./images/fileTypeIcon/flowComp.svg",
                                altText: "Documents completed",
                                label: "Instances Completed",
                                value: reportObj.instancesCompleted,
                            },
                            {
                                iconSrc: "./images/fileTypeIcon/flowTime.svg",
                                altText: "on time",
                                label: "Instances On Time",
                                value: reportObj.instancesInitiated,
                            },
                            {
                                iconSrc:
                                    "./images/fileTypeIcon/flowDelayed.svg",
                                altText: "Delayed documents",
                                label: "Instances Delayed",
                                value: reportObj.instancesDelayed,
                            },
                            {
                                iconSrc: "./images/fileTypeIcon/flowFile.svg",
                                altText: "total documents",
                                label: "Documents",
                                value: reportObj.totalDocuments,
                            },
                            {
                                iconSrc: "./images/fileTypeIcon/pro.svg",
                                altText: "total proactive Alerts",
                                label: "Proactive Alerts",
                                value: reportObj.totalProactiveAlerts,
                            },
                        ];
                        return (
                            <div>
                                {index === 0 && getAggregatedRow()}
                                <div
                                    style={{
                                        flexBasis: "10%",
                                        marginTop: "20px",
                                        boxShadow:
                                            "1px 1px 20px 4px rgb(0 0 0 / 10%)",
                                        borderRadius: "10px",
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <h4 className="displayName textEllipsis">
                                        {Object.keys(item)[0]}
                                    </h4>
                                    <div
                                        style={{
                                            padding: "12px",
                                            display: "flex",
                                            justifyContent: "space-around",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        {reportProperties.map((ele, index) => {
                                            return (
                                                <div className="measure-block">
                                                    <span
                                                        style={{
                                                            padding: "8px",
                                                        }}
                                                    >
                                                        <img
                                                            className="dashboard-icons"
                                                            src={ele.iconSrc}
                                                            alt={ele.altText}
                                                        />
                                                    </span>
                                                    <span
                                                        style={{
                                                            marginRight: "4px",
                                                        }}
                                                    >
                                                        {ele.label}:
                                                    </span>
                                                    <span>
                                                        {index === 2
                                                            ? reportObj.instancesCompleted -
                                                                  reportObj.instancesDelayed >
                                                              0
                                                                ? reportObj.instancesCompleted -
                                                                  reportObj.instancesDelayed
                                                                : 0
                                                            : ele.value > 0
                                                            ? ele.value
                                                            : 0}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {reportObj.genericElements.length ? (
                                        <CustomTable
                                            spaceData={
                                                reportObj.genericElements
                                            }
                                            type="report"
                                            height="500px"
                                            setSelectedItems={setSelectedItems}
                                        ></CustomTable>
                                    ) : null}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}

            <Loader show={showLoader}></Loader>
        </div>
    );
};

export default AggrDashboard;

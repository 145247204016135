import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AlertDialog from "../../../../../stylized/AlertDialog";
import { fetchSpaceListData } from "../../../../../../actions/SpaceActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchWorkspaceListData } from "../../../../../../actions/WokspaceActions";
import http from "../../../../../../bae-client/HTTP";
import MuiDateTimePicker from "../../../../../stylized/MuiDateTimePicker";
import { ValidateEmail } from "../../../../../../bae-client/Utils";
import { validateNameLength } from "../../../../../../bae-client/Utils";
import ListView from "../../../../../stylized/ListView";
import PopoverView from "../../../../../stylized/PopoverView";
import { Icon } from "@material-ui/core";
import "./FromWrapper.css";
import { fetchHierarchyResponse } from "../../../../../../actions/HierarchyActions";
import CustomSnackbar from "../../../../../stylized/CustomSnackbar/CustomSnackbar";
import user from "../../../../../../bae-client/User";
// import ans from "./properties.js";
import AddFolderModal from "../../../ContentPane/WorkspaceContainer/AddFolderModal";
import config from "../../../../../../config";
import {
    validateName,
    validateStepperName,
} from "../../../../../../bae-client/Validations";
import TextBox from "../../../../../stylized/TextBox";
import { AlertTitle, Checkbox, Switch } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import { Menu } from "@mui/material";
import { setFolderIdToNameMap } from "../../../../../../actions/FolderActions";
import "./style.css";
import { setActivePage } from "../../../../../../actions";
import * as Mixpanel from "../../../../../../mix-panel/Mixpanel";
import {
    getFormattedDate,
    convertLocalDateIntoUTCDate,
    convertServerDateIntoUTC,
} from "../../../../../../bae-client/Utils";
import Loader from "../../../../../stylized/Loader";
import FileSelectionTree from "../../Integration/FormWrapper/FileSelectTree";
import Inputs from "../../../../../Inputs";
import StorageModal from "./StorageModal.jsx";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps(currentAction) {
    const steps = [currentAction === "edit" ? "Edit Step" : "Create New Step"];

    if (currentAction !== "edit") {
        steps.push("Select Storage");
    }
    steps.push("Confirm");

    return steps;
}

function AddStepStepper({
    isChecked,
    setIsChecked,
    nameBranch,
    onHide,
    countV,
    setCountV,
    accountType,
    Display,
    fetchWorkspaceListData,
    defaultStep,
    handleShareAttachment,
    heading,
    hideSpace,
    hideFolder,
    hideFile,
    formType,
    selectedRow,
    saveButtonName,
    updateFilterData,
    filterRawObj,
    curruntAction,
    filters,
    addNewStep,
    genericStepList,
    editStepData,
    isInstanceStep,
    confirmMsg,
    title,
    selectedInstanceSteps,
    parentStepId,
    genericSteps,
}) {
    const [docTitle, setdocTitle] = React.useState("");
    const [disableExpectDur, setDisableExpectDur] = useState(false);
    const [name, setName] = React.useState("");
    const [selectedOption, setSelectedOption] = useState("duration");
    const [newFolderName, setNewFolderName] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const dispatch = useDispatch();
    const [stepId, setStepId] = React.useState("");
    const [errMsg, setErrMsg] = React.useState("");
    const [stepStorage, setStepStorage] = React.useState("");
    const [addFolderModalShow, setAddFolderModalShow] = useState(false);
    const [dropdownOptions, setDropdownOptions] = React.useState([]);
    const [countValue, setCountValue] = React.useState("0");
    const [countTypeValue, setCountTypeValue] = React.useState("Minutes");
    const [branchValues, setBranchValues] = useState("Main");
    const [branchId, setBranchId] = useState({
        branchId: 1,
        branchName: "Main",
    });
    const [timeValue, setTimeValue] = React.useState("");
    const [selectedDropdown, setSelectedDropdown] = React.useState("");
    const [isOptional, setIsOptional] = React.useState(false);
    const [selectTime, setSelectTime] = React.useState(false);
    const [selectDay, setSelectDay] = React.useState(false);
    const [fromValue, setFromValue] = React.useState("");
    const [recentUploadedFolder, setRecentUploadedFolder] = useState(null);
    const [recentUploadedFolderId, setRecentUploadedFolderId] = useState(null);
    const [parentFolderId, setParentFolderId] = useState("0");
    const [toValue, setToValue] = React.useState("");
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [promiseStageValue, setPromiseStageValue] = React.useState("");
    const [showMore, setShowMore] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorNameMessage, setErrorNameMessage] = React.useState("");
    const [nextClicked, setNextClicked] = React.useState(false);
    const [promiseStageErrorMessage, setPromiseStageErrorMessage] =
        React.useState("");
    const [WorkspaceId, setWorkspaceId] = useState(null);

    const [error, setError] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedDateTime, setSelectedDateTime] = useState(new Date());
    const [selectedPrevStep, setSelectedPrevStep] = useState(null);
    const [selectedView, setSelectedView] = useState(null);
    const [expanded, setExpanded] = React.useState([]);
    const [selected, setSelected] = useState([]);
    const [isCustomChecked, setIsCustomChecked] = useState(false);
    const classes = useStyles();
    const [isChange, setIsChange] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(defaultStep || 0);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps(curruntAction);
    // Handler for the custom checkbox
    const handleCustomChange = (event) => {
        setIsCustomChecked(event.target.checked);
    };
    const selectHandler = (node) => {
        setSelectedFolder({
            folderId: node.id,
            folderName: node.objectName,
            documentId: node.documentId,
            childrenFolderNames: node.children.map((e) => e.objectName),
        });
        setSelectedView(node);
    };
    function transformData(data, isFile) {
        if (isFile) {
            return Object.keys(data).reduce((acc, key) => {
                const document = data[key];
                if (document.documentId === 0) {
                    acc.push({
                        id: parseInt(key),
                        ...document,
                        // isFolder: true,
                        children: transformData(
                            document.children || {},
                            isFile
                        ),
                    });
                }
                return acc;
            }, []);
        }
        return Object.keys(data).map((key) => {
            const document = data[key];
            return {
                id: parseInt(key),
                ...document,
                children: transformData(document.children || {}, isFile),
            };
        });
    }
    const open = Boolean(anchorEl);
    const changeOpenDialogFlag = (value) => {
        setShowAlertDialog(value);
        setAlertDialogMessage("");
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        setNextClicked(!nextClicked);
    };

    const getDurationForStep = (mins) => {
        const min = mins;
        const hours = Math.floor(mins / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const year = Math.floor(months / 12);
        if (year > 0) {
            return `${year} Year`;
        } else if (months > 0) {
            return `${months} Months`;
        } else if (days > 0) {
            return `${days} Days`;
        } else if (hours > 0) {
            return `${hours} hours after previous step`;
        } else return `${min} minutes after previous step`;
    };
    const getButtonName = () => {
        if (defaultStep) return saveButtonName || "Share";
        if (activeStep === 1) return "Submit";
        else return "Next";
    };
    useEffect(() => {
        if (selectedInstanceSteps && selectedInstanceSteps.length) {
            const prevStep = selectedInstanceSteps.find(
                (step) => parentStepId === step.instanceStepId
            );
            let branchId = {};
            if (prevStep)
                branchId = {
                    branchId: prevStep.branchId,
                    branchName: prevStep.branchName,
                };
            else branchId = { branchId: 1, branchName: "Main" };
            setBranchId(branchId);
            setBranchValues(prevStep ? prevStep.branchName : "Main");
        }
    }, [selectedInstanceSteps]);

    useEffect(() => {
        if (editStepData !== undefined && editStepData !== null) {
            // if (
            //     editStepData.folderIdPath !== "" &&
            //     editStepData.folderIdPath !== undefined
            // ) {
            //     const folderIdValue = editStepData.folderIdPath;
            //     const latestValue = folderIdValue.includes("/")
            //         ? folderIdValue.split("/").pop()
            //         : folderIdValue;

            //     getFolderPath(latestValue);
            // }

            if (
                !isInstanceStep &&
                editStepData &&
                editStepData.storageLocation
            ) {
                setStepStorage(
                    editStepData.storageLocation.split("/").join("/ ")
                );
            }

            setStepId(editStepData.genericStepUniqueIdentifier);
            setBranchValues(nameBranch);
            //setBranchId(editStepData.branchId);

            setName(editStepData.genericStepName);
            setFromValue(editStepData.fromEntity);
            setToValue(editStepData.toEntity);

            const duration = getDurationForStep(
                editStepData.expectedDuration
            ).split(" ");
            setCountValue(duration[0]);
            setCountTypeValue(duration[1]);
            if (isInstanceStep) {
                genericStepList.forEach((instanceStep) => {
                    if (
                        instanceStep.instanceStepId ===
                        editStepData.expectedDurationFrom
                    ) {
                        setSelectedPrevStep({
                            id: instanceStep.instanceStepId,
                            value: instanceStep.instanceStepName,
                        });
                    }
                });
            } else {
                genericStepList.forEach((genericStep) => {
                    if (
                        genericStep.genericStepId ===
                        editStepData.expectedDurationFrom
                    ) {
                        setSelectedPrevStep({
                            id: genericStep.genericStepId,
                            value: genericStep.genericStepName,
                        });
                    }
                });
            }
            setPromiseStageValue(
                editStepData.promiseType ? editStepData.promiseType : "None"
            );
            setIsOptional(
                editStepData.blockType &&
                    editStepData.blockType.toLowerCase().trim() === "optional"
                    ? true
                    : false
            );
            setSelectedDateTime(
                editStepData.expectedDate
                    ? convertServerDateIntoUTC(editStepData.expectedDate)
                    : selectedDateTime
            );

            workspaceList.forEach((ws) => {
                if (ws.workspaceId === editStepData.workspaceId) {
                    loadSpaceData(ws.workspaceId);
                    setSelectedWorkspace({
                        workspaceId: ws.workspaceId,
                        workspaceName: ws.workspaceName,
                    });
                    setFolderList({});
                }
            });
        }
    }, [editStepData]);

    useEffect(() => {
        if (
            editStepData !== undefined &&
            editStepData !== null &&
            curruntAction === "edit" &&
            selectedWorkspace.workspaceName === ""
        ) {
            workspaceList.forEach((ws) => {
                if (ws.workspaceId === editStepData.workspaceId) {
                    loadSpaceData(ws.workspaceId);
                    setSelectedWorkspace({
                        workspaceId: ws.workspaceId,
                        workspaceName: ws.workspaceName,
                    });
                    setFolderList({});
                }
            });
        }
    }, [activeStep]);
    let spaceNewId;

    useEffect(() => {
        if (isCustomChecked === false && !isInstanceStep) {
            const workspaceNameToFind = "Flow";

            const findWorkspaceByName = (workspaceName) => {
                return workspaceList.find(
                    (workspace) => workspace.workspaceName === workspaceName
                );
            };

            const Workspace = findWorkspaceByName(workspaceNameToFind);

            if (Workspace) {
                const workspaceId = Workspace.workspaceId; // Use a local variable to store the value
                setWorkspaceId(workspaceId); // Update the state with the value
                dispatch(fetchSpaceListData(workspaceId));

                const targetSpaceName =
                    selectedRow && selectedRow.genericDisplayName;

                if (spaceListData) {
                    const foundSpace = spaceListData.find(
                        (item) => item.spaceName === targetSpaceName
                    );

                    if (foundSpace) {
                        spaceNewId = foundSpace.spaceId; // Assuming spaceNewId is declared somewhere
                    }
                }
            }
        }
    }, [isCustomChecked]);

    useEffect(() => {
        if (curruntAction === "edit") {
            if (isInstanceStep) {
                if (
                    editStepData.expectedDate !== "" &&
                    editStepData.expectedDurationFrom === -1
                ) {
                    setSelectedOption("date");
                } else {
                    setSelectedOption("duration"); // 2nd option
                }
            } else {
                setSelectedOption("duration");
            }
        }
    }, [curruntAction]);

    useEffect(() => {
        isInstanceStep &&
            editStepData &&
            http
                .sendRestRequest(
                    `documentChain/instance/step/storage?genericId=${editStepData.genericId}&instanceStepId=${editStepData.instanceStepId}`
                )
                .then((res) => {
                    setStepStorage(res.storageLocation.split("/").join("/ "));
                })
                .catch((err) => {
                    console.log(err);
                });
        return () => {
            setStepStorage("");
        };
    }, [editStepData]);
    const getExpectedDurationInMinutes = () => {
        if (countTypeValue.toLocaleLowerCase() === "days")
            return countValue * 24 * 60;
        else if (countTypeValue.toLocaleLowerCase() === "weeks")
            return countValue * 24 * 60 * 7;
        else if (countTypeValue.toLocaleLowerCase() === "months")
            return countValue * 24 * 60 * 30;
        else if (countTypeValue.toLocaleLowerCase() === "hours")
            return countValue * 60;
        else if (countTypeValue.toLocaleLowerCase() === "minutes")
            return parseInt(countValue);
        else return parseInt(countValue);
    };
    const showAlertView = (title, body, messageId, buttonId, isError) => {
        setAlertDialogContent({
            title: title,
            message: body,
            messageId: messageId,
            buttonId: buttonId,
        });
        isError ? setAlertDialogShow(true) : setShowSnackbar(true);
    };
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const setText = (value, id, event) => {
        if (value && value.length > 100)
            setError("Name should not exceed 100 char length");
        else setError("");
        switch (id) {
            case "newFolderName": {
                setNewFolderName(value);
            }
            default:
                break;
        }
    };
    const addNewFolder = () => {
        if (error) return;
        if (newFolderName.trim().length > 0) {
            if (
                Object.values(selectedFolder.childrenFolderNames)
                    .filter((e) => e.documentId === 0)
                    .map((e) => e.objectName.toUpperCase())
                    .includes(newFolderName.toUpperCase().trim())
            ) {
                setError("Folder name already exists");
                return;
            } else if (
                selectedFolder.folderId === 0 &&
                Object.values(folderList)
                    .filter((e) => e.documentId === 0)
                    .map((e) => e.objectName)
                    .includes(newFolderName.trim())
            ) {
                setError("Folder name already exists");
                return;
            } else if (
                selectedFolder.folderId > 0 &&
                selectedFolder.childrenFolderNames.includes(
                    newFolderName.trim()
                )
            ) {
                setError("Folder name already exists");
                return;
            }
            if (!validateNameLength(newFolderName)) {
                let msg = "Folder name should be less than 100 characters";
                setError(msg);
                return;
            }
            setShowLoader(true);
            setAddFolderModalShow(false);

            let spaceId = selectedSpace.spaceId;
            let parentId = parentFolderId;
            let data = {
                spaceId: spaceId,
                folderName: newFolderName.trim(),
                parentFolderId: selectedFolder.folderId,
            };

            let url = "folders/folder";

            http.sendRestRequest(url, "POST", data)
                .then((resp) => {
                    if (resp) {
                        Mixpanel.callFolderManagementEvent("Add");
                        showAlertView(
                            "",
                            "Folder added successfully",
                            "alert_folder",
                            "btn_folder_close"
                        );
                        setRecentUploadedFolder(resp);
                        setRecentUploadedFolderId(resp.folderRegistryId);
                        setNewFolderName("");
                        setOpenSecondModal(true);
                        setShowLoader(false);
                        Display(false);
                        // gerateFolderListForSpaceId(spaceId);
                        setSelectedFolder({
                            folderId: resp.folderRegistryId,
                            folderName: newFolderName.trim(),
                            parentId: selectedFolder.folderId,
                            childrenFolderNames: [],
                        });
                        getFolderPath(resp.folderRegistryId);
                    }
                })
                .catch((err) => {
                    Display(false);
                    setNewFolderName("");
                    setShowAlertDialog(true);
                    setAlertDialogMessage(err.error);

                    setShowLoader(false);
                });
        } else {
            setError("Please enter folder name");
            return;
        }
        if (!validateNameLength(newFolderName)) {
            let msg = "Folder name should be less than 100 characters";
            setError(msg);
            return;
        }
    };
    const findWorkspaceByName = (workspaceName) => {
        const foundWorkspace = workspaceList.find(
            (workspace) => workspace.workspaceName === workspaceName
        );

        return foundWorkspace;
    };

    const handleCloseModal = () => {
        setStepId("");
        setName("");
        setDropdownOptions([]);
        setCountValue("0");
        setCountTypeValue("");
        setTimeValue("");
        setSelectedDropdown("");
        setIsOptional(false);
        setSelectTime(false);
        setSelectDay(false);
        setShowMore(false);
        setPromiseStageValue("");
        setToValue("");
        setFromValue("");
        setErrorMessage("");
        setErrorNameMessage("");
        setPromiseStageErrorMessage("");
        onHide();
    };
    const handleAddFolderClick = () => {
        Display(true);
        setAddFolderModalShow(true);
        setOpenSecondModal(false);
    };

    const closeAddFolderClick = () => {
        Display(false);
        setAddFolderModalShow(false);
        setError("");
        setOpenSecondModal(true);
    };
    const fromToValue = (e) => {
        const isValidName = validateStepperName(name);

        if (!fromValue.trim() && !toValue.trim()) {
            setError({
                from: "cannot be empty",
                to: "cannot be empty",
            });
            return false;
        } else if (isValidName !== true) {
            setError({
                from: "check",
                to: "check",
            });
            return false;
        } else return true;
    };

    const validateStepId = (e) => {
        const stepUniqueIds = genericStepList.map(
            (step) => step.genericStepUniqueIdentifier
        );
        if (stepId.trim().length > 100) {
            setErrorMessage("Maximum length less than 100");
            return false;
        } else if (!name && !isInstanceStep) {
            setErrorNameMessage("Step name cannot be empty");
            return false;
        } else if (
            stepUniqueIds.includes(stepId.trim()) &&
            curruntAction !== "edit"
        ) {
            setErrorMessage("Step Id Already Exist");
        } else return true;
    };

    const validateStepName = (e) => {
        const stepNames = genericStepList.map((stepName) =>
            stepName.genericStepName.trim().toUpperCase()
        );
        const isValidName = validateName(name);
        if (!name && !isInstanceStep) {
            setErrorNameMessage("Name cannot be empty");
            return false;
        } else if (isValidName !== true && !isInstanceStep) {
            setErrorNameMessage(isValidName);
            return false;
        } else if (
            (editStepData === null || editStepData === undefined) &&
            stepNames.includes(name.trim().toUpperCase())
        ) {
            setErrorNameMessage("Step Name Already Exist");
            return false;
        } else if (
            editStepData !== null &&
            editStepData !== undefined &&
            editStepData.genericStepName.toLowerCase().trim() !==
                name.toLowerCase().trim() &&
            stepNames.includes(name.trim().toUpperCase())
        ) {
            setErrorNameMessage("Step Name Already Exist");
            return false;
        } else return true;
    };

    const handleChangeFolderId = () => {
        if (
            selectedFolder.folderId === "" ||
            selectedFolder.folderId === 0 ||
            selectedFolder.folderId === "0"
        ) {
            if (editStepData?.folderIdPath) {
                handleSubmit(editStepData.folderIdPath);
            } else {
                handleSubmit("");
            }
        } else {
            const folderId = selectedFolder.folderId;
            let latestValue;
            if (typeof folderId === "string") {
                latestValue = folderId.includes("/")
                    ? folderId.split("/").pop()
                    : folderId;
            } else {
                latestValue = folderId.toString();
            }
            http.sendRestRequest(
                `folders/folderDetails?folderId=${latestValue}`
            )
                .then((response) => {
                    handleSubmit(response.folderIdPath);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    const handleSubmit = (folderIds) => {
        let stageValue = promiseStageValue;
        let trimmedStepId = stepId.trim();
        let trimmedFromValue = fromValue.trim();
        let trimmedToValue = toValue.trim();

        // Check for empty workspace or space
        // if (!selectedWorkspace.workspaceId || !selectedSpace.spaceId) {
        //     setErrorMessage("Workspace and Space cannot be empty.");
        //     return;
        // }

        if (stageValue === "None") {
            stageValue = "";
        }
        setNewStorages(false);
        if (validateStepId() && validateStepName()) {
            if (!editStepData) {
                if (isInstanceStep) {
                    addNewStep(
                        {
                            genericStepUniqueIdentifier: trimmedStepId
                                ? trimmedStepId
                                : name.trim(),
                            genericStepName: name.trim(),
                            fromEntity: trimmedFromValue,
                            toEntity: trimmedToValue,
                            promiseType: stageValue,
                            expectedDuration:
                                selectedOption === "duration"
                                    ? getExpectedDurationInMinutes()
                                    : 0,
                            expectedDurationFrom: selectedPrevStep
                                ? selectedPrevStep.id
                                : -1,
                            expectedDate:
                                selectedOption === "date"
                                    ? getFormattedDate(
                                          convertLocalDateIntoUTCDate(
                                              selectedDateTime
                                          )
                                      )
                                    : "",
                            informationAvailable: "",
                            workspaceId:
                                isCustomChecked === false && !isInstanceStep
                                    ? WorkspaceId
                                    : selectedWorkspace.workspaceId,
                            spaceId: selectedSpace.spaceId,
                            folderIdPath:
                                selectedFolder.folderId === "" ? "" : folderIds,
                            blockType: isOptional ? "Optional" : "Promise",
                            branchId: branchId.branchId,
                            branchName: branchId.branchName,
                        },
                        isInstanceStep,
                        true
                    );
                } else {
                    if (isCustomChecked === false) {
                        addNewStep(
                            {
                                genericStepUniqueIdentifier: trimmedStepId
                                    ? trimmedStepId
                                    : name.trim(),
                                genericStepName: name.trim(),
                                fromEntity: trimmedFromValue,
                                toEntity: trimmedToValue,
                                promiseType: stageValue,
                                expectedDuration:
                                    getExpectedDurationInMinutes(),
                                expectedDurationFrom: selectedPrevStep
                                    ? selectedPrevStep.id
                                    : -1,
                                informationAvailable: "",
                                workspaceId: WorkspaceId,
                                spaceId: spaceNewId,
                                folderIdPath: folderIds,
                                blockType: isOptional ? "Optional" : "Promise",
                            },
                            isInstanceStep,
                            !isCustomChecked
                        );
                    } else {
                        addNewStep(
                            {
                                genericStepUniqueIdentifier: trimmedStepId
                                    ? trimmedStepId
                                    : name.trim(),
                                genericStepName: name.trim(),
                                fromEntity: trimmedFromValue,
                                toEntity: trimmedToValue,
                                promiseType: stageValue,
                                expectedDuration: disableExpectDur
                                    ? 0
                                    : getExpectedDurationInMinutes(),
                                expectedDurationFrom: selectedPrevStep
                                    ? selectedPrevStep.id
                                    : -1,
                                informationAvailable: "",
                                workspaceId: selectedWorkspace.workspaceId,
                                spaceId: selectedSpace.spaceId,
                                folderIdPath: folderIds,
                                blockType: isOptional ? "Optional" : "Promise",
                            },
                            isInstanceStep,
                            false
                        );
                    }
                }
            } else {
                // Handle edits when editStepData exists
                if (
                    name.trim() !== editStepData.genericStepName ||
                    trimmedStepId !==
                        editStepData.genericStepUniqueIdentifier ||
                    stageValue !== editStepData.promiseType ||
                    countValue !==
                        getDurationForStep(editStepData.expectedDuration).split(
                            " "
                        )[0] ||
                    countTypeValue !==
                        getDurationForStep(editStepData.expectedDuration).split(
                            " "
                        )[1] ||
                    selectedWorkspace.workspaceId !==
                        editStepData.workspaceId ||
                    selectedSpace.spaceId !== editStepData.spaceId ||
                    selectedFolder.folderId !== editStepData.folderId ||
                    isOptional !== editStepData.blockType ||
                    selectedDateTime !== editStepData.expectedDate
                ) {
                    addNewStep(
                        isInstanceStep
                            ? {
                                  instanceStepUniqueIdentifier: trimmedStepId
                                      ? trimmedStepId
                                      : name.trim(),
                                  genericStepName: name.trim(),
                                  fromEntity: trimmedFromValue,
                                  toEntity: trimmedToValue,
                                  promiseType: stageValue,
                                  expectedDuration:
                                      selectedOption === "duration"
                                          ? getExpectedDurationInMinutes()
                                          : editStepData.expectedDuration,
                                  expectedDurationFrom: selectedPrevStep
                                      ? selectedPrevStep.id
                                      : -1,
                                  expectedDate:
                                      selectedOption === "date"
                                          ? getFormattedDate(
                                                convertLocalDateIntoUTCDate(
                                                    selectedDateTime
                                                )
                                            )
                                          : editStepData.expectedDate,
                                  informationAvailable: "",
                                  workspaceId: selectedWorkspace.workspaceId,
                                  spaceId: selectedSpace.spaceId,
                                  folderIdPath:
                                      selectedFolder.folderId === ""
                                          ? ""
                                          : folderIds,
                                  blockType: isOptional
                                      ? "Optional"
                                      : "Promise",
                              }
                            : {
                                  genericStepUniqueIdentifier: trimmedStepId
                                      ? trimmedStepId
                                      : name.trim(),
                                  genericStepName: name.trim(),
                                  fromEntity: trimmedFromValue,
                                  toEntity: trimmedToValue,
                                  promiseType: stageValue,
                                  expectedDuration: disableExpectDur
                                      ? 0
                                      : getExpectedDurationInMinutes(),
                                  expectedDurationFrom: selectedPrevStep
                                      ? selectedPrevStep.id
                                      : -1,
                                  informationAvailable: "",
                                  workspaceId: selectedWorkspace.workspaceId,
                                  spaceId: selectedSpace.spaceId,
                                  folderIdPath:
                                      selectedFolder.folderId === ""
                                          ? ""
                                          : folderIds,
                                  blockType: isOptional
                                      ? "Optional"
                                      : "Promise",
                              },
                        isInstanceStep
                    );
                }
            }

            // Reset form fields
            setName("");
            setStepId("");
            setIsChange(false);
            setDropdownOptions([]);
            setCountValue("0");
            setCountTypeValue("Minutes");
            setTimeValue("");
            setSelectedDropdown("");
            setIsOptional(false);
            setSelectTime(false);
            setSelectDay(false);
            setShowMore(false);
            setErrorMessage("");
            setErrorNameMessage("");
            setPromiseStageValue("");
            setToValue("");
            setFromValue("");
            resetError();
        }
    };

    const resetError = () => {
        setError({ from: "", to: "" });
    };
    const onChange = (value, id) => {
        const trimmedValue = value.trim(); // Trim spaces

        if (errorMessage) setErrorMessage("");
        if (errorNameMessage) setErrorNameMessage("");
        if (error && (error.from || error.to)) {
            resetError();
        }

        if (id === "stepId") {
            setStepId(value);
        }
        if (id === "stepName") {
            setName(value);
        }
        if (id === "fromValue") {
            let isValidName = validateStepperName(value);
            if (isValidName === true) {
                setFromValue(value);
            } else {
                setFromValue(value);
            }
        }
        if (id === "toValue") {
            let isValidName = validateStepperName(value);
            if (isValidName === true) {
                setToValue(value);
            } else {
                setToValue(value);
            }
        } else {
            setdocTitle(trimmedValue);
            setErrMsg("");
        }
    };

    const checkValue = (period, value) => {
        if (period === "Months") {
            return value >= 1 && value <= 12;
        } else if (period === "Weeks") {
            return value >= 1 && value <= 52;
        } else if (period === "Days") {
            return value >= 1 && value <= 30;
        } else if (period === "Hours") {
            return value >= 1 && value <= 24;
        } else if (period === "Minutes") {
            return value >= 0 && value <= 60;
        }
        return false;
    };
    // const getValue = () => {
    //     prevStep = dropdownOptions.filter((option) => option.value);
    //     return prevStep[0];
    // };
    const getPrevSteps = () => {
        let prevSteps = [];
        let stepList =
            isInstanceStep && selectedInstanceSteps
                ? selectedInstanceSteps
                : genericStepList;
        let genericStepUniqueIds = genericSteps.map(
            (step) => step.genericStepUniqueIdentifier
        );
        for (let i = 0; i < stepList.length; i++) {
            let stepId = stepList[i].instanceStepId
                ? stepList[i].instanceStepId
                : stepList[i].genericStepId;
            if (
                genericStepUniqueIds.includes(
                    stepList[i].genericStepUniqueIdentifier
                )
            ) {
                if (stepList[i].instanceStepId) {
                    prevSteps.push({
                        id: stepList[i].instanceStepId,
                        value: stepList[i].instanceStepName,
                    });
                } else {
                    prevSteps.push({
                        id: stepList[i].genericStepId,
                        value: stepList[i].genericStepName,
                    });
                }
            }
            if (stepId === parentStepId) {
                break;
            }
        }

        return prevSteps;
    };
    const handleClick = (event) => {
        setSelectedDropdown("");
        setAnchorEl(event.currentTarget);
        if (event.currentTarget && event.currentTarget.id === "count") {
            setSelectedDropdown("count");
            if (countTypeValue === "Hours") {
                setDropdownOptions([
                    { id: 1, value: 1 },
                    { id: 2, value: 2 },
                    { id: 3, value: 3 },
                    { id: 4, value: 4 },
                    { id: 5, value: 5 },
                    { id: 6, value: 6 },
                    { id: 7, value: 7 },
                    { id: 8, value: 8 },
                    { id: 9, value: 9 },
                    { id: 10, value: 10 },
                    { id: 11, value: 11 },
                    { id: 12, value: 12 },
                    { id: 13, value: 13 },
                    { id: 14, value: 14 },
                    { id: 15, value: 15 },
                    { id: 16, value: 16 },
                    { id: 17, value: 17 },
                    { id: 18, value: 18 },
                    { id: 19, value: 19 },
                    { id: 20, value: 20 },
                    { id: 21, value: 21 },
                    { id: 22, value: 22 },
                    { id: 23, value: 23 },
                    { id: 24, value: 24 },
                ]);
            } else if (countTypeValue === "Days") {
                setDropdownOptions([
                    { id: 1, value: 1 },
                    { id: 2, value: 2 },
                    { id: 3, value: 3 },
                    { id: 4, value: 4 },
                    { id: 5, value: 5 },
                    { id: 6, value: 6 },
                    { id: 7, value: 7 },
                    { id: 8, value: 8 },
                    { id: 9, value: 9 },
                    { id: 10, value: 10 },
                    { id: 11, value: 11 },
                    { id: 12, value: 12 },
                    { id: 13, value: 13 },
                    { id: 14, value: 14 },
                    { id: 15, value: 15 },
                    { id: 16, value: 16 },
                    { id: 17, value: 17 },
                    { id: 18, value: 18 },
                    { id: 19, value: 19 },
                    { id: 20, value: 20 },
                    { id: 21, value: 21 },
                    { id: 22, value: 22 },
                    { id: 23, value: 23 },
                    { id: 24, value: 24 },
                    { id: 25, value: 25 },
                    { id: 26, value: 26 },
                    { id: 27, value: 27 },
                    { id: 28, value: 28 },
                    { id: 29, value: 29 },
                    { id: 30, value: 30 },
                ]);
            } else if (countTypeValue === "Weeks") {
                setDropdownOptions([
                    { id: 1, value: 1 },
                    { id: 2, value: 2 },
                    { id: 3, value: 3 },
                    { id: 4, value: 4 },
                    { id: 5, value: 5 },
                    { id: 6, value: 6 },
                    { id: 7, value: 7 },
                    { id: 8, value: 8 },
                    { id: 9, value: 9 },
                    { id: 10, value: 10 },
                    { id: 11, value: 11 },
                    { id: 12, value: 12 },
                    { id: 13, value: 13 },
                    { id: 14, value: 14 },
                    { id: 15, value: 15 },
                    { id: 16, value: 16 },
                    { id: 17, value: 17 },
                    { id: 18, value: 18 },
                    { id: 19, value: 19 },
                    { id: 20, value: 20 },
                    { id: 21, value: 21 },
                    { id: 22, value: 22 },
                    { id: 23, value: 23 },
                    { id: 24, value: 24 },
                    { id: 25, value: 25 },
                    { id: 26, value: 26 },
                    { id: 27, value: 27 },
                    { id: 28, value: 28 },
                    { id: 29, value: 29 },
                    { id: 30, value: 30 },
                    { id: 31, value: 31 },
                    { id: 32, value: 32 },
                    { id: 33, value: 33 },
                    { id: 34, value: 34 },
                    { id: 35, value: 35 },
                    { id: 36, value: 36 },
                    { id: 37, value: 37 },
                    { id: 38, value: 38 },
                    { id: 39, value: 39 },
                    { id: 40, value: 40 },
                    { id: 41, value: 41 },
                    { id: 42, value: 42 },
                    { id: 43, value: 43 },
                    { id: 44, value: 44 },
                    { id: 45, value: 45 },
                    { id: 46, value: 46 },
                    { id: 47, value: 47 },
                    { id: 48, value: 48 },
                    { id: 49, value: 49 },
                    { id: 50, value: 50 },
                    { id: 51, value: 51 },
                    { id: 52, value: 52 },
                ]);
            } else if (countTypeValue === "Months") {
                setDropdownOptions([
                    { id: 1, value: 1 },
                    { id: 2, value: 2 },
                    { id: 3, value: 3 },
                    { id: 4, value: 4 },
                    { id: 5, value: 5 },
                    { id: 6, value: 6 },
                    { id: 7, value: 7 },
                    { id: 8, value: 8 },
                    { id: 9, value: 9 },
                    { id: 10, value: 10 },
                    { id: 11, value: 11 },
                    { id: 12, value: 12 },
                ]);
            } else {
                setDropdownOptions([
                    { id: 1, value: 1 },
                    { id: 2, value: 2 },
                    { id: 3, value: 3 },
                    { id: 4, value: 4 },
                    { id: 5, value: 5 },
                    { id: 6, value: 6 },
                    { id: 7, value: 7 },
                    { id: 8, value: 8 },
                    { id: 9, value: 9 },
                    { id: 10, value: 10 },
                    { id: 11, value: 11 },
                    { id: 12, value: 12 },
                    { id: 13, value: 13 },
                    { id: 14, value: 14 },
                    { id: 15, value: 15 },
                    { id: 16, value: 16 },
                    { id: 17, value: 17 },
                    { id: 18, value: 18 },
                    { id: 19, value: 19 },
                    { id: 20, value: 20 },
                    { id: 21, value: 21 },
                    { id: 22, value: 22 },
                    { id: 23, value: 23 },
                    { id: 24, value: 24 },
                    { id: 25, value: 25 },
                    { id: 26, value: 26 },
                    { id: 27, value: 27 },
                    { id: 28, value: 28 },
                    { id: 29, value: 29 },
                    { id: 30, value: 30 },
                    { id: 31, value: 31 },
                    { id: 32, value: 32 },
                    { id: 33, value: 33 },
                    { id: 34, value: 34 },
                    { id: 35, value: 35 },
                    { id: 36, value: 36 },
                    { id: 37, value: 37 },
                    { id: 38, value: 38 },
                    { id: 39, value: 39 },
                    { id: 40, value: 40 },
                    { id: 41, value: 41 },
                    { id: 42, value: 42 },
                    { id: 43, value: 43 },
                    { id: 44, value: 44 },
                    { id: 45, value: 45 },
                    { id: 46, value: 46 },
                    { id: 47, value: 47 },
                    { id: 48, value: 48 },
                    { id: 49, value: 49 },
                    { id: 50, value: 50 },
                    { id: 51, value: 51 },
                    { id: 52, value: 52 },
                    { id: 53, value: 53 },
                    { id: 54, value: 54 },
                    { id: 55, value: 55 },
                    { id: 56, value: 56 },
                    { id: 57, value: 57 },
                    { id: 58, value: 58 },
                    { id: 59, value: 59 },
                ]);
            }
        }
        if (event.currentTarget && event.currentTarget.id === "countType") {
            setSelectedDropdown("countType");
            setDropdownOptions([
                { id: 5, value: "Minutes" },
                { id: 1, value: "Hours" },
                { id: 2, value: "Days" },
                { id: 3, value: "Weeks" },
                { id: 4, value: "Months" },
            ]);
        }
        if (event.currentTarget && event.currentTarget.id === "prevStep") {
            setSelectedDropdown("prevStep");
            let prevSteps = [{ id: "", value: "None" }];
            let stepList =
                isInstanceStep && selectedInstanceSteps
                    ? selectedInstanceSteps
                    : genericStepList;
            for (let i = 0; i < stepList.length; i++) {
                let stepId = stepList[i].instanceStepId
                    ? stepList[i].instanceStepId
                    : stepList[i].genericStepId;
                if (stepList[i].blockType !== "Optional") {
                    if (stepList[i].instanceStepId) {
                        prevSteps.push({
                            id: stepList[i].instanceStepId,
                            value: stepList[i].instanceStepName,
                        });
                    } else {
                        prevSteps.push({
                            id: stepList[i].genericStepId,
                            value: stepList[i].genericStepName,
                        });
                    }
                }
                if (stepId === parentStepId) {
                    break;
                }
            }
            setDropdownOptions(prevSteps);
        }
        if (event.currentTarget && event.currentTarget.id === "time") {
            setSelectedDropdown("time");
            setDropdownOptions([
                { id: 1, value: "day" },
                { id: 2, value: "week" },
                { id: 3, value: "month" },
            ]);
        }
        if (event.currentTarget && event.currentTarget.id === "promiseStage") {
            setPromiseStageErrorMessage("");
            setSelectedDropdown("promiseStage");
            setDropdownOptions([
                { id: 1, value: "None" },
                { id: 2, value: "Initiation" },
                { id: 3, value: "Commitment" },
                { id: 4, value: "Fulfillment" },
                { id: 5, value: "Settlement" },
                { id: 6, value: "Completion" },
            ]);
        }
        if (
            event.currentTarget &&
            event.currentTarget.id === "branchSelection"
        ) {
            const data = selectedInstanceSteps
                .filter((step) => step.blockType === "Promise")
                .map((step) => step.branchName)
                .filter(
                    (branch, index, self) => self.indexOf(branch) === index
                );
            setSelectedDropdown("branch");
            setDropdownOptions(
                data.map((branch, index) => {
                    return { id: index, value: branch };
                })
            );
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === " " && name.length === 0) {
            e.preventDefault();
        }
    };

    const handleKeyDownOnStepId = (e) => {
        if (e.key === " " && stepId.trim().length === 0) {
            e.preventDefault();
        }
    };
    const handleClose = (e) => {
        // handleCloseInstanceDropdown(e);
        // setDropdownValue(e.target.innerHTML);
        if (selectedDropdown === "count") {
            if (e.target.innerHTML === "") {
                setCountValue(countValue);
            } else {
                setCountValue(e.target.innerHTML);
            }
        }
        if (selectedDropdown === "countType") {
            // setCountValue("0");
            e.target.innerHTML === ""
                ? setCountTypeValue("Minutes")
                : setCountTypeValue(e.target.innerHTML);
        }
        if (selectedDropdown === "time") {
            setTimeValue(e.target.innerHTML);
        }
        if (selectedDropdown === "promiseStage") {
            setPromiseStageValue(e.target.innerHTML);

            if (genericStepList !== undefined) {
                if (
                    genericStepList
                        .map((item) => item.promiseType)
                        .includes(e.target.innerHTML)
                )
                    setPromiseStageErrorMessage(
                        "The selected stage already present!"
                    );
            }
        }
        if (selectedDropdown === "branch") {
            const selectedBranch = selectedInstanceSteps.filter(
                (step) => step.branchName === e.target.innerHTML
            );
            const branchId = selectedBranch.length
                ? selectedBranch[0].branchId
                : 1;
            const selectedBranchName = selectedBranch.length
                ? selectedBranch[0].branchName
                : "Main";
            setBranchId({ branchId: branchId, branchName: selectedBranchName });
            setBranchValues(selectedBranchName);
        }
        if (selectedDropdown === "prevStep") {
            const selectedPrevStep = dropdownOptions.filter(
                (option) => option.value === e.target.innerHTML
            );
            if (selectedPrevStep[0].value === "None") {
                setDisableExpectDur(true);
                setSelectedPrevStep(selectedPrevStep[0]);
            } else {
                setDisableExpectDur(false);
                if (selectedPrevStep.length > 0) {
                    setSelectedPrevStep(selectedPrevStep[0]);
                } else if (selectedPrevStep === null) {
                    let prevSteps = getPrevSteps();

                    setSelectedPrevStep(prevSteps[prevSteps.length - 1]);
                }
            }
        } else if (
            selectedDropdown !== "prevStep" &&
            selectedPrevStep === null
        ) {
            let prevSteps = getPrevSteps();

            setSelectedPrevStep(prevSteps[prevSteps.length - 1]);
        }
        setAnchorEl(null);
    };

    const cuboidInfoByName = useSelector((state) => state.cuboidInfoByName);
    const spaceManager = useSelector((state) => state.spaceManager);
    const workspaceList = useSelector((state) => state.workspaceList);

    const spaceListData = useSelector((state) => state.spaceListData);
    const filterList = useSelector((state) => state.filterList);
    const [apiStatus, setApiStatus] = useState(null);
    const [apiError, setApiError] = useState("");
    const [isFolderListLoaded, setIsFolderListLoaded] = useState(false);
    const [metaIds, setMetaIds] = useState({
        workspaceCollabId: null,
        workspaceWBId: null,
        workspaceRegistryId: null,
        workspacePersistenceId: null,
        spaceWBId: null,
        spaceRegistryId: null,
        spacePersistenceId: null,
    });

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [alertDialogShow, setAlertDialogShow] = useState(false);
    const [newStorages, setNewStorages] = useState(false);
    const [openSecondModal, setOpenSecondModal] = React.useState(false);
    const [alertDialogContent, setAlertDialogContent] = useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonId: "",
    });
    const [formOneInput, setFormOneInput] = useState({
        nameFilter: "",
        from: "",
        subject: "",
        emailBody: "",
        emailSubject: "",
    });
    const [selectedWorkspace, setSelectedWorkspace] = useState({
        workspaceId: "",
        workspaceName: "",
    });
    const [selectedSpace, setSelectedSpace] = useState({
        spaceId: "",
        spaceName: "",
    });
    const [selectedFolder, setSelectedFolder] = useState({
        folderId: "",
        folderName: "",
        documentId: "",
        childrenFolderNames: [],
    });
    const [spaceArray, setSpaceArray] = useState([]);
    const [folderList, setFolderList] = useState(null);
    const [selectedFolderStructure, setSelectedFolderStructure] =
        useState(null);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        if (countValue === "") {
            setCountValue("0999999");
        }
    }, [countValue]);

    useEffect(() => {}, []);
    useEffect(() => {
        if (cuboidInfoByName !== null) {
            let dataByName = cuboidInfoByName;
            setMetaIds({
                workspaceCollabId: dataByName.get("__Workspace"),
                workspaceWBId: dataByName.get("Workspace"),
                workspaceRegistryId: dataByName.get("Workspace Registry"),
                workspacePersistenceId: dataByName.get("Workspace Persistence"),
                spaceWBId: dataByName.get("Space"),
                spaceRegistryId: dataByName.get("Space Registry"),
                spacePersistenceId: dataByName.get("Space Persistence"),
            });
        }
    }, [metaIds.workspaceCollabId === null]);
    useEffect(() => {
        if (metaIds.workspaceCollabId) {
            let options = {
                workspaceCollabId: metaIds.workspaceCollabId,
                workspaceWBId: metaIds.workspaceWBId,
                workspaceRegistryId: metaIds.workspaceRegistryId,
                workspacePersistenceId: metaIds.workspacePersistenceId,
            };
            fetchWorkspaceListData(options);
        }
    }, [metaIds.workspaceCollabId]);

    useEffect(() => {
        return () => {
            setFormOneInput({
                nameFilter: "",
                from: "",
                subject: "",
                emailBody: "",
                emailSubject: "",
            });
            setSelectedWorkspace({ workspaceId: "", workspaceName: "" });
            setSelectedSpace({ spaceId: "", spaceName: "" });
            setSelectedFolder({
                folderId: "",
                folderName: "",
                documentId: "",
                childrenFolderNames: [],
            });
        };
    }, []);
    const getSummaryAndConfirmation = () => {
        return (
            <div className="modal-container">
                <div className="marginTopTen invisibleScroller">
                    <div>
                        <strong>Step Data</strong>
                    </div>
                    <div style={{ cursor: "pointer" }}>
                        <span>Unique Identifier : </span>
                        <span title={stepId ? stepId : "Not Given"}>
                            {stepId ? stepId : "Not Given"}
                        </span>
                    </div>
                    <div style={{ cursor: "pointer" }}>
                        <span>Name : </span>
                        <span title={name}>{name}</span>
                    </div>
                    <div style={{ cursor: "pointer" }}>
                        <span>Optional : </span>
                        <span title={isOptional ? "Yes" : "No"}>
                            {isOptional ? "Yes" : "No"}
                        </span>
                    </div>
                    <div style={{ cursor: "pointer" }}>
                        <span>Expected In : </span>
                        <span
                            title={
                                countValue
                                    ? countValue + " " + countTypeValue
                                    : "Not Selected"
                            }
                        >
                            {countValue
                                ? countValue + " " + countTypeValue
                                : "Not Selected"}
                        </span>
                    </div>
                    <div style={{ cursor: "pointer" }}>
                        <span>From : </span>
                        <span title={fromValue ? fromValue : "Not Selected"}>
                            {fromValue ? fromValue : "Not Selected"}
                        </span>
                    </div>
                    <div style={{ cursor: "pointer" }}>
                        <span>To : </span>
                        <span title={toValue ? toValue : "Not Selected"}>
                            {toValue ? toValue : "Not Selected"}
                        </span>
                    </div>
                    <div style={{ cursor: "pointer" }}>
                        <span>Promise Stage : </span>
                        <span
                            title={
                                promiseStageValue
                                    ? promiseStageValue
                                    : "Not Selected"
                            }
                        >
                            {promiseStageValue
                                ? promiseStageValue
                                : "Not Selected"}
                        </span>
                    </div>
                    <div>
                        <strong>Storage</strong>
                    </div>

                    <div
                        title={selectedWorkspace.workspaceName}
                        className="textEllipsis"
                        style={{ width: "315px", margin: 0, cursor: "pointer" }}
                    >
                        <span>Workspace: </span>
                        <span>
                            {selectedWorkspace.workspaceName
                                ? selectedWorkspace.workspaceName
                                : isCustomChecked === false
                                ? "Flow"
                                : selectedWorkspace.workspaceName}
                        </span>
                    </div>

                    <div style={{ cursor: "pointer" }}>
                        <span>Space: </span>
                        <span
                            title={selectedSpace.spaceName}
                            className="textEllipsis"
                            style={{ width: "315px", margin: 0 }}
                        >
                            {selectedSpace.spaceName
                                ? selectedSpace.spaceName
                                : isCustomChecked === false
                                ? selectedRow?.genericDisplayName
                                : selectedSpace.spaceName}
                        </span>
                    </div>

                    <div style={{ cursor: "pointer" }}>
                        <span>Folder: </span>
                        <span
                            title={
                                selectedFolder.folderName &&
                                selectedFolder.folderName.trim() !== ""
                                    ? selectedFolder.folderName
                                    : newStorages === false
                                    ? name
                                    : selectedFolder.folderName
                            }
                            className="textEllipsis"
                        >
                            {selectedFolder.folderName &&
                            selectedFolder.folderName.trim() !== ""
                                ? selectedFolder.folderName
                                : newStorages === false
                                ? name
                                : selectedFolder.folderName}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const isTimeDelayDDDisabled = (editStepData) => {
        if (
            (editStepData && editStepData.documentName) ||
            genericStepList.length === 0
        ) {
            return true;
        }
        if (
            editStepData &&
            (editStepData.genericOrderId === 1 ||
                editStepData.parentInstanceStepId === "0")
        ) {
            return true;
        }
        return false;
    };

    const renderFormOne = () => {
        let userInfo = user.getUserInfo();
        return (
            <div style={{ position: "relative", paddingLeft: "12px" }}>
                <div className="formLabel invisibleScroller">
                    {
                        <div className="modalRow">
                            <div>*Name</div>
                            <Inputs
                                onChange={onChange}
                                value={name}
                                type="text"
                                id="stepName"
                                disable={
                                    editStepData &&
                                    editStepData.durationStatus === "DONE"
                                        ? true
                                        : false
                                }
                                placeholder="Name"
                                handleKeyDown={handleKeyDown}
                                isError={errMsg && errMsg.length > 0}
                                icon={false}
                            />
                            {errMsg && (
                                <>
                                    <small
                                        style={{
                                            color: "red",
                                            paddingLeft: "45px",
                                            paddingTop: "10px",
                                        }}
                                    >
                                        {errMsg}
                                    </small>
                                </>
                            )}
                        </div>
                    }
                    {errorNameMessage && (
                        <>
                            <small style={{ color: "red" }}>
                                {errorNameMessage}
                            </small>
                        </>
                    )}
                    {
                        <>
                            <div className="modalSubRow">
                                <div style={{ width: "53%" }}>From</div>
                                <div style={{ width: "47%" }}>To</div>
                            </div>
                            <div className="modalSubRow">
                                <div
                                    style={{
                                        maxWidth: "48%",
                                        marginRight: "20px",
                                    }}
                                >
                                    <Inputs
                                        id="fromValue"
                                        type="text"
                                        onChange={onChange}
                                        value={fromValue}
                                        placeholder="From"
                                        handleKeyDown={handleKeyDown}
                                        isError={error && error.from}
                                        icon={false}
                                        showMore={showMore}
                                    />
                                </div>
                                <div style={{ maxWidth: "48%" }}>
                                    <Inputs
                                        id="toValue"
                                        type="text"
                                        onChange={onChange}
                                        value={toValue}
                                        placeholder="To"
                                        handleKeyDown={handleKeyDown}
                                        isError={error && error.to}
                                        icon={false}
                                        showMore={showMore}
                                    />
                                </div>
                            </div>
                        </>
                    }

                    {showMore ? (
                        <>
                            {
                                <div className="modalRow">
                                    <div>Unique Identifier</div>
                                    <Inputs
                                        id="stepId"
                                        type="text"
                                        onChange={onChange}
                                        value={stepId}
                                        placeholder="Id"
                                        handleKeyDown={handleKeyDownOnStepId}
                                        isError={errMsg && errMsg.length > 0}
                                        icon={false}
                                        disable={
                                            curruntAction === "edit" &&
                                            !config.editable(
                                                sessionStorage.user
                                            )
                                                ? true
                                                : false
                                        }
                                    />
                                    {errMsg && (
                                        <>
                                            <small
                                                style={{
                                                    color: "red",
                                                    paddingLeft: "45px",
                                                    paddingTop: "10px",
                                                }}
                                            >
                                                {errMsg}
                                            </small>
                                        </>
                                    )}
                                </div>
                            }
                            {errorMessage && (
                                <>
                                    <small style={{ color: "red" }}>
                                        {errorMessage}
                                    </small>
                                </>
                            )}

                            {curruntAction === "edit" && (
                                <div
                                    className="modalRow"
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <div title={stepStorage}>
                                        <div>Selected Storage</div>
                                        <Inputs
                                            id=""
                                            type="text"
                                            onChange={onChange}
                                            value={stepStorage}
                                            placeholder="Storage"
                                            handleKeyDown={() => {}}
                                            isError={false}
                                            icon={false}
                                            disable={true}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            alignItems: "center",
                                            display: "flex",
                                            padding: "29px 6px 1px",
                                        }}
                                    >
                                        <button
                                            id="changeStorage"
                                            onClick={clickNewMoveHandler}
                                            disabled={
                                                editStepData &&
                                                editStepData.durationStatus ===
                                                    "DONE"
                                                    ? true
                                                    : false
                                            }
                                            style={{
                                                width: "152px",
                                                height: "37px",

                                                gap: "8px",
                                                borderRadius: "4px ",
                                                border: "1px solid #D7DEEA",
                                                background: "#FFFFFF",
                                                opacity: 1,
                                            }}
                                        >
                                            Change Storage
                                        </button>
                                    </div>
                                </div>
                            )}
                            {/* {isInstanceStep ? (<div
                                className="modalRow"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <div>
                                    <div>Selected Storage</div>
                                    <Inputs
                                        id=""
                                        type="text"
                                        onChange={onChange}
                                        value={stepStorage}
                                        placeholder="Storage"
                                        handleKeyDown={() => { }}
                                        isError={false}
                                        icon={false}
                                        disable={true}
                                    />
                                </div>
                                <div
                                    style={{
                                        alignItems: "center",
                                        display: "flex",
                                        padding: "29px 6px 1px",
                                    }}
                                >
                                    <button
                                        id="changeStorage"
                                        onClick={clickNewMoveHandler}
                                        style={{
                                            width: "152px",
                                            height: "37px",

                                            gap: "8px",
                                            borderRadius: "4px ",
                                            border: "1px solid #D7DEEA",
                                            background: "#FFFFFF",
                                            opacity: 1,
                                        }}
                                    >
                                        Change Storage
                                    </button>
                                </div>
                            </div>

                            ) : null} */}
                            {isInstanceStep ? (
                                <div className="modalRow">
                                    <div>Select Branch</div>
                                    <div className="modalSubRow">
                                        <div
                                            style={{
                                                margin: "0px 12px 0px 0px",
                                                width: "200px",
                                            }}
                                        >
                                            <Button
                                                id="branchSelection"
                                                variant="outlined"
                                                disableElevation
                                                onClick={handleClick}
                                                endIcon={
                                                    <KeyboardArrowDownIcon id="button_branch_icon" />
                                                }
                                                className={
                                                    // curruntAction === "edit"
                                                    //     ? "disabledButtonStyle"
                                                    //     : "buttonStyle"
                                                    "disabledButtonStyle"
                                                }
                                                // disabled={
                                                //     editStepData &&
                                                //     editStepData.documentName
                                                //         ? true
                                                //         : false
                                                // }
                                                disabled={
                                                    // curruntAction === "edit"
                                                    //     ? true
                                                    //     : false
                                                    true
                                                }
                                            >
                                                <span className="textEllipsis">
                                                    {branchValues || "Branches"}
                                                </span>
                                            </Button>
                                        </div>
                                    </div>
                                    {errMsg && (
                                        <>
                                            <small
                                                style={{
                                                    color: "red",
                                                    paddingLeft: "45px",
                                                    paddingTop: "10px",
                                                }}
                                            >
                                                {errMsg}
                                            </small>
                                        </>
                                    )}
                                </div>
                            ) : null}

                            {!isInstanceStep && (
                                <div
                                    className="modalSubRow"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px", // Add spacing between the switch and label
                                    }}
                                >
                                    {" "}
                                    <span>Make Step Optional</span>
                                    <span>
                                        <Switch
                                            defaultChecked={isOptional}
                                            checked={isOptional}
                                            onChange={(e) =>
                                                setIsOptional(e.target.checked)
                                            }
                                        />
                                    </span>
                                </div>
                            )}
                            {curruntAction === "edit" ? null : (
                                <div
                                    style={{ marginBottom: "10px" }}
                                    id="div_customizeStorage_checkbox"
                                >
                                    <input
                                        type="checkbox"
                                        checked={isCustomChecked}
                                        onChange={handleCustomChange}
                                    />

                                    <span
                                        style={{
                                            marginLeft: "10px",
                                            marginBottom: "2px",
                                        }}
                                    >
                                        Customize Storage (Optional)
                                    </span>
                                </div>
                            )}
                            {isInstanceStep && (
                                <div className="modalRow">
                                    <div
                                        style={{
                                            display: "flex",
                                            textAlign: "center",
                                        }}
                                    >
                                        <div>
                                            <input
                                                type="radio"
                                                name="option"
                                                value="date"
                                                disabled={
                                                    editStepData &&
                                                    editStepData.durationStatus ===
                                                        "DONE"
                                                        ? true
                                                        : false
                                                }
                                                checked={
                                                    selectedOption === "date"
                                                }
                                                onChange={handleOptionChange}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                paddingBottom: "10px",
                                                paddingLeft: " 12px ",
                                            }}
                                        >
                                            Select date/time for step to occur
                                        </div>
                                    </div>

                                    <div className="modalSubRow">
                                        <div
                                            style={{
                                                margin: "0px 12px 0px 0px",
                                            }}
                                        >
                                            <MuiDateTimePicker
                                                selectedOption={
                                                    selectedOption ===
                                                    "duration"
                                                        ? true
                                                        : false
                                                }
                                                selectedDateTime={
                                                    selectedDateTime
                                                }
                                                setSelectedDateTime={
                                                    setSelectedDateTime
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="modalRow">
                                <div
                                    style={{
                                        display: "flex",
                                        textAlign: "center",
                                    }}
                                >
                                    {isInstanceStep && (
                                        <div>
                                            <input
                                                type="radio"
                                                name="option"
                                                value="duration"
                                                checked={
                                                    selectedOption ===
                                                    "duration"
                                                }
                                                onChange={handleOptionChange}
                                                disabled={
                                                    isTimeDelayDDDisabled(
                                                        editStepData
                                                    ) ||
                                                    (editStepData &&
                                                        editStepData.durationStatus ===
                                                            "DONE")
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            paddingBottom: "10px",
                                            paddingLeft: isInstanceStep
                                                ? "12px"
                                                : "0px",
                                        }}
                                    >
                                        Select time delay after previous step
                                    </div>
                                </div>
                                <div className="modalSubRow">
                                    <div style={{ margin: "0px 12px 0px 0px" }}>
                                        <Button
                                            id="prevStep"
                                            variant="outlined"
                                            disableElevation
                                            onClick={handleClick}
                                            endIcon={<KeyboardArrowDownIcon />}
                                            disabled={
                                                isTimeDelayDDDisabled(
                                                    editStepData
                                                ) ||
                                                (selectedOption === "date" &&
                                                    isInstanceStep)
                                                    ? true
                                                    : false
                                            }
                                            style={{
                                                width: "187.26px",
                                                height: "36px",
                                                padding: "7px 16px",
                                                gap: "24px",
                                                borderRadius: "4.32px",
                                                opacity: 1,
                                                boxShadow:
                                                    "0px 2px 4px 0px #0000000A, 0px 1px 1px 0px #00000005",
                                            }}
                                        >
                                            <span className="textEllipsis">
                                                {(selectedPrevStep &&
                                                    selectedPrevStep.value) ||
                                                    "Previous Steps"}
                                            </span>
                                        </Button>
                                    </div>
                                    <div style={{ margin: "0px 12px 0px 0px" }}>
                                        <Button
                                            id="countType"
                                            variant="outlined"
                                            disableElevation
                                            onClick={
                                                disableExpectDur
                                                    ? null
                                                    : handleClick
                                            }
                                            endIcon={<KeyboardArrowDownIcon />}
                                            disabled={
                                                isTimeDelayDDDisabled(
                                                    editStepData
                                                ) ||
                                                (selectedOption === "date" &&
                                                    isInstanceStep) ||
                                                disableExpectDur
                                                    ? true
                                                    : false
                                            }
                                            style={{
                                                width: "120.48px",
                                                height: "36px",
                                                padding: "7px 16px",
                                                gap: "0px",
                                                borderRadius: "4.32px ",

                                                justifyContent: "space-between",
                                                opacity: 1,
                                                boxShadow:
                                                    "0px 2px 4px 0px #0000000A, 0px 1px 1px 0px #00000005", // Box shadow added
                                            }}
                                        >
                                            {countTypeValue || "Minutes"}
                                        </Button>
                                    </div>

                                    {/* Third Button */}
                                    <div style={{ margin: "0px 12px 0px 0px" }}>
                                        <Button
                                            id="count"
                                            variant="outlined"
                                            disableElevation
                                            onClick={
                                                disableExpectDur
                                                    ? null
                                                    : handleClick
                                            }
                                            endIcon={<KeyboardArrowDownIcon />}
                                            disabled={
                                                isTimeDelayDDDisabled(
                                                    editStepData
                                                ) ||
                                                (selectedOption === "date" &&
                                                    isInstanceStep) ||
                                                disableExpectDur
                                                    ? true
                                                    : false
                                            }
                                            style={{
                                                width: "83.26px",
                                                height: "36px",
                                                padding: "7px 16px",
                                                gap: "0px",
                                                borderRadius: "4.32px",

                                                justifyContent: "space-between",
                                                opacity: 1,
                                                boxShadow:
                                                    "0px 2px 4px 0px #0000000A, 0px 1px 1px 0px #00000005", // Box shadow added
                                            }}
                                        >
                                            {curruntAction === "edit"
                                                ? countValue
                                                : checkValue(
                                                      countTypeValue,
                                                      countValue
                                                  )
                                                ? countValue
                                                : "0"}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="modalRow">
                                {isInstanceStep ? null : (
                                    <div className="modalRow">
                                        <div>Promise Stage</div>
                                        <div>
                                            <Button
                                                id="promiseStage"
                                                variant="outlined"
                                                disableElevation
                                                onClick={handleClick}
                                                endIcon={
                                                    <KeyboardArrowDownIcon />
                                                }
                                                className=""
                                                style={{
                                                    width: "97%", // Fixed width
                                                    height: "36px", // Fixed height
                                                    padding: "7px 16px", // Padding
                                                    gap: "0px", // Gap
                                                    borderRadius: "4.32px ", // Border-radius
                                                    border: "1.08px solid #D7DEEA", // Border
                                                    justifyContent:
                                                        "space-between", // Justify content
                                                    backgroundColor: "#FFFFFF", // Background color
                                                    opacity: 1, // Opacity (set to visible)
                                                    boxShadow:
                                                        "0px 2px 4px 0px #0000000A, 0px 1px 1px 0px #00000005", // Box shadow
                                                    marginBottom: "12px", // Spacing below the button
                                                }}
                                            >
                                                {promiseStageValue ||
                                                    "Promise Stage"}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : null}
                </div>
                {
                    //!isInstanceStep && (
                    <div
                        id={showMore ? "div_showLess" : "div_showMore"}
                        style={{
                            textDecoration: "underline",
                        }}
                        className="formLabel cursorPointer"
                        onClick={() => setShowMore(!showMore)}
                    >
                        {showMore
                            ? "Hide Advanced Settings"
                            : "Show Advanced Settings"}
                    </div>
                    //)
                }
                <Menu
                    classes={{ list: "menu-custom" }}
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {dropdownOptions.map((instance, index) => {
                        return (
                            <MenuItem
                                className="menu-item-custom"
                                key={instance.id}
                                onClick={handleClose}
                                disableRipple
                                id={"menuItem_" + instance.id}
                            >
                                {instance.value}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        );
    };
    const [showSpacePopover, setShowSpacePopover] = useState(false);
    const [spacePopoverTarget, setSpacePopoverTarget] = useState(null);
    const showSpacePopoverClick = (e) => {
        setShowSpacePopover(true);
        setSpacePopoverTarget(e.currentTarget);
    };

    const closeSpacePopover = (e) => {
        setShowSpacePopover(false);
        setSpacePopoverTarget(null);
    };
    const handleFolderSelection = (event, currentObject, children) => {
        if (currentObject !== undefined) {
            setSelectedFolder({
                folderId: currentObject.id,
                folderName: currentObject.objectName,
                documentId: currentObject.documentId,
                childrenFolderNames: children.children.map((e) => e.objectName),
            });

            setSelectedFolderStructure(children);
            setRecentUploadedFolderId(currentObject.id);
        }
    };
    const checkFolder = (parentFolderId, name, folderList) => {};
    // const getFolderListForSpace = (spaceId) => {
    //     console.log("usinf");
    //     return new Promise((resolve, reject) => {
    //         let url = `folderEntity/objectList?spaceId=${spaceId}`;
    //         http.sendRestRequest(url)
    //             .then((data) => {
    //                 resolve(data);
    //             })
    //             .catch((err) => {
    //                 // showAlertView(
    //                 //     "Operation Failed",
    //                 //     err && err.error
    //                 //         ? err.error
    //                 //         : "Something went wrong, please try again!",
    //                 //     "",
    //                 //     "",
    //                 //     true
    //                 // );
    //                 setShowAlertDialog(true);
    //                 setAlertDialogMessage(err.error);
    //                 console.log(err);
    //             });
    //     });
    // };
    const getFolderPath = (folderId) => {
        http.sendRestRequest(`folders/folderDetails?folderId=${folderId}`)
            .then((resp) => {
                let FolderNamePath = resp.folderNamePath.split("/");
                setSelectedFolder({
                    folderId: folderId,
                    folderName: FolderNamePath[FolderNamePath.length - 1],
                    documentId: "",
                    childrenFolderNames: [],
                });
                let expandedPath = resp.folderIdPath.split("/");
                setSelected(expandedPath[expandedPath.length - 1]);
                expandedPath.pop();
                expandedPath.reverse();
                setExpanded(expandedPath);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // const gerateFolderListForSpaceId = (spaceId, type) => {
    //     setShowLoader(true);
    //     setIsFolderListLoaded(false);
    //     // getFolderListForSpace(spaceId)
    //         .then((folderList) => {
    //             setShowLoader(false);
    //             if (type !== "fromWs") {
    //                 setFolderList(folderList);
    //                 setIsFolderListLoaded(true);
    //                 if (
    //                     folderList &&
    //                     editStepData &&
    //                     editStepData.folderIdPath &&
    //                     typeof folderList === "object"
    //                 ) {
    //                     for (const key in folderList) {
    //                         if (
    //                             Object.hasOwnProperty.call(folderList, key) &&
    //                             parseInt(key) ===
    //                                 parseInt(editStepData.folderIdPath)
    //                         ) {
    //                             setSelectedFolder({
    //                                 folderId: editStepData.folderIdPath,
    //                                 folderName: folderList[key].objectName,
    //                                 documentId: "",
    //                                 childrenFolderNames: [],
    //                             });
    //                         }
    //                     }
    //                 }
    //             }
    //         })
    //         .catch((err) => {
    //             setShowLoader(false);
    //             setShowAlertDialog(true);
    //             setAlertDialogMessage(err.error);
    //             console.log(err);
    //         });
    // };

    const handleSpaceListItemClick = (value, text, event, i) => {
        let temp = [];
        let spaceFolderList = [];
        spaceArray.forEach((space) => {
            if (space.text === text) {
                // gerateFolderListForSpaceId(space.id);
                setSelectedSpace({ spaceId: space.id, spaceName: space.text });
                setSelectedFolder({
                    folderId: "",
                    folderName: "",
                    documentId: "",
                    childrenFolderNames: [],
                });
                closeSpacePopover(i);
            }
        });
    };
    const renderSpaceMenuItems = () => {
        return (
            <ListView
                className="noBorder noOutline"
                listItemClassname="textEllipsis"
                listRowClassname="row m-0"
                id={"departmentList"}
                label={"Department List"}
                items={spaceArray}
                showIcon="false"
                disableActiveIndex="true"
                onchange={handleSpaceListItemClick}
            />
        );
    };
    const renderSpacePopover = () => {
        return (
            <PopoverView
                id={"menu_Department"}
                width="35rem"
                thOrigin="bottom"
                ahOrigin="left"
                show={showSpacePopover}
                anchorEl={spacePopoverTarget}
                closePopover={closeSpacePopover}
            >
                {renderSpaceMenuItems()}
            </PopoverView>
        );
    };
    const generateSpaceDropdown = () => {
        return (
            <div className="row m-1 w-100 doCenter mb-3 dropDownWrapper noBorderFocus">
                {renderSpacePopover()}
                <Button
                    tabIndex="4"
                    id="inp_signUp_department_dropDown"
                    onClick={showSpacePopoverClick}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        textTransform: "none",
                    }}
                    className="inp_TextBox"
                >
                    <div
                        className="w-100 textEllipsis"
                        style={{ textAlign: "left" }}
                    >
                        <span>
                            {selectedSpace.spaceName !== ""
                                ? selectedSpace.spaceName
                                : "Select Spaces"}
                        </span>
                    </div>
                    <Icon
                        id="icon_spaces_flow"
                        className={"fas fa-angle-down dropdownIcon"}
                    ></Icon>
                </Button>
            </div>
        );
    };
    const loadSpaceData = (workspaceId, spaceObj, type) => {
        let url = `workspace/spaceList?workspaceId=${workspaceId}`;
        setShowLoader(true);
        http.sendRestRequest(url)
            .then((spaceList) => {
                setShowLoader(false);
                let spaceListArray = [];
                if (spaceList !== null) {
                    spaceList.map((item, i) => {
                        let obj = {};
                        obj["text"] = item.spaceName;
                        obj["value"] = item.spaceName;
                        obj["id"] = item.spaceId;
                        spaceListArray.push(obj);
                        if (
                            editStepData &&
                            item.spaceId === editStepData.spaceId
                        ) {
                            if (type !== "fromWs") {
                                setSelectedSpace({
                                    spaceId: item.spaceId,
                                    spaceName: item.spaceName,
                                });
                            }
                            if (
                                editStepData.folderIdPath !== "" &&
                                editStepData.folderIdPath !== undefined
                            ) {
                                const folderIdValue = editStepData.folderIdPath;
                                const latestValue = folderIdValue.includes("/")
                                    ? folderIdValue.split("/").pop()
                                    : folderIdValue;

                                getFolderPath(latestValue);
                            }
                            // gerateFolderListForSpaceId(item.spaceId, type);
                        }
                    });
                    setSpaceArray(spaceListArray);
                }
            })
            .catch((err) => {
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(err.error);
                console.log(err);
            });
    };
    const handleWSListItemClick = (value, text, event, i) => {
        workspaceList.forEach((ws) => {
            if (ws.workspaceName === text) {
                loadSpaceData(ws.workspaceId, null, "fromWs");
                setSelectedWorkspace({
                    workspaceId: ws.workspaceId,
                    workspaceName: ws.workspaceName,
                });
                closeWorkspacePopover(i);
                setSelectedSpace({ spaceId: "", spaceName: "" });
                setSelectedFolder({
                    folderId: "",
                    folderName: "",
                    documentId: "",
                    childrenFolderNames: [],
                });
                setFolderList({});
            }
        });
    };
    const renderWorkspaceMenuItems = () => {
        let workspaceItems = [];

        let workspaceTree = workspaceList.filter(
            (workspace) => workspace.workspaceType !== "SYSTEM"
        );
        if (workspaceTree !== null && workspaceTree !== undefined) {
            workspaceTree.forEach((element) => {
                workspaceItems.push({
                    text: element.workspaceName,
                    value: element.workspaceName,
                    id: element.workspaceId,
                });
            });
            workspaceItemsList = workspaceItems;
        } else {
            workspaceItems.push({
                text: "No Items",
                value: "No Items",
                id: "001",
            });
        }
        return (
            <ListView
                className="noBorder"
                listItemClassname="textEllipsis"
                listRowClassname="row m-0"
                id={"departmentList"}
                label={"Department List"}
                items={workspaceItems}
                showIcon="false"
                disableActiveIndex="true"
                onchange={handleWSListItemClick}
            />
        );
    };
    const renderWorkspacePopover = () => {
        // id={"menu_" + spaceID}
        return (
            <PopoverView
                id={"menu_Department"}
                show={showWsPopover}
                anchorEl={wsPopoverTarget}
                width="35rem"
                thOrigin="bottom"
                ahOrigin="left"
                closePopover={closeWorkspacePopover}
            >
                {renderWorkspaceMenuItems()}
            </PopoverView>
        );
    };
    const [showWsPopover, setShowWsPopover] = useState(false);
    const [wsPopoverTarget, setWsPopoverTarget] = useState(null);
    const showWorkspacePopover = (e) => {
        setShowWsPopover(true);
        setWsPopoverTarget(e.currentTarget);
    };
    const closeWorkspacePopover = (e) => {
        setShowWsPopover(false);
        setWsPopoverTarget(null);
    };
    let workspaceItemsList = null;
    const generateWorkspaceDropdown = () => {
        return (
            <div className="row m-1 w-100 doCenter mb-3 dropDownWrapper noBorderFocus">
                {renderWorkspacePopover()}
                <Button
                    tabIndex="4"
                    id="inp_signUp_department_dropDown"
                    onClick={showWorkspacePopover}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        textTransform: "none",
                    }}
                    className="inp_TextBox"
                >
                    <div
                        className="w-100 textEllipsis"
                        style={{ textAlign: "left" }}
                    >
                        <span>
                            {selectedWorkspace.workspaceName !== ""
                                ? selectedWorkspace.workspaceName
                                : "Select Workspaces"}
                        </span>
                    </div>
                    <Icon
                        id="icon_selectworkspace_flow"
                        className={"fas fa-angle-down dropdownIcon"}
                    ></Icon>
                </Button>
            </div>
        );
    };
    const clickNewMoveHandler = () => {
        setOpenSecondModal(true);
        setNewStorages(true);
        setSelectedWorkspace({
            workspaceId: "",
            workspaceName: "",
        });
        setSelectedSpace({ spaceId: "", spaceName: "" });
        setSelectedFolder({
            folderId: "",
            folderName: "",
            documentId: "",
            childrenFolderNames: [],
        });
        setSpaceArray([]);
        setFolderList(null);
    };
    const renderDropdowns = () => {
        return (
            <div className="doCenter" style={{ flexDirection: "column" }}>
                <>
                    {generateWorkspaceDropdown()}
                    {hideSpace ? null : generateSpaceDropdown()}
                </>
                {hideSpace || hideFolder ? null : (
                    <>
                        <span
                            className="w-100 fontFamily"
                            style={{ fontSize: "14px" }}
                        >
                            Select
                            {hideFile ? " Folder" : " File"}
                        </span>
                        <div
                            className="tree-view invisibleScroller"
                            // style={{ height: "25vh" }}
                        >
                            <FileSelectionTree
                                data={
                                    folderList &&
                                    transformData(folderList, hideFile)
                                }
                                selectHandler={selectHandler}
                                setSelected={setSelected}
                                setExpanded={setExpanded}
                                expanded={expanded}
                                selected={selected}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    };
    const getStepContent = (step, currentAction) => {
        switch (step) {
            case 0:
                return renderFormOne();

            case 1:
                return getSummaryAndConfirmation();

            default:
                return "Unknown step";
        }
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleChangeStorage = () => {
        if (activeStep === 0) {
            setIsChange(true);
            if (!validateStepId() || !validateStepName()) return;
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setNextClicked(false);
            setSelectedWorkspace({
                workspaceId: "",
                workspaceName: "",
            });
            setSelectedSpace({ spaceId: "", spaceName: "" });
            setSelectedFolder({
                folderId: "",
                folderName: "",
                documentId: "",
                childrenFolderNames: [],
            });
            setSpaceArray([]);
            setFolderList(null);
        }
    };
    const handleNext = () => {
        if (activeStep === 0) {
            // Validate step before proceeding
            if (!validateStepId() || !validateStepName()) return;

            // If the custom option is checked, open the modal first
            if (isCustomChecked === true && openSecondModal === true) {
                setActiveStep(1);
                setOpenSecondModal(false);
                return;
            }
            if (isCustomChecked) {
                setActiveStep(0);
                setOpenSecondModal(true);
                return;
            }
            setActiveStep(1);
            setNextClicked(false);
        }

        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        if (isChange === false && curruntAction === "edit") {
            // Reset step to 1 for editing
            setActiveStep(1);
            setSkipped(newSkipped);
        } else if (openSecondModal === true && curruntAction === "edit") {
            setActiveStep(2);
            setSkipped(newSkipped);
            setOpenSecondModal(false);
        } else {
            // If we are in edit mode and modal is already open, move to step 2
            if (
                (openSecondModal === true && curruntAction === "edit") ||
                (isCustomChecked === true && openSecondModal === true)
            ) {
                setActiveStep(1);
                setSkipped(newSkipped);
                setOpenSecondModal(false); // Close the modal
            } else {
                // Regular flow for moving to the next step
                setActiveStep(1);
                setSkipped(newSkipped);
            }
        }

        setOpenSecondModal(false);
    };
    const handleBack = () => {
        if (defaultStep) onHide();
        if (isCustomChecked === false && !isInstanceStep && activeStep !== 1) {
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
        } else if (isChange === false && activeStep !== 1) {
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
        setErrorMsg("");
    };

    const handleReset = () => {
        onHide();
    };

    const isButtonDisable = () => {
        if (activeStep === 0) {
            if (name !== "" && branchId) return false;
            else return true;
        }

        // if (
        //     selectedWorkspace.workspaceName === "" &&
        //     selectedSpace.spaceName === ""
        // ) {
        //     return true;
        // }
    };

    const getUpdateStatus = () => {
        if (apiStatus === "success") {
            return "Filter Applied!";
        } else if (apiStatus === "failure") {
            return "Filter not applied!";
        } else return "Please wait..!";
    };

    const NewFolder = () => {
        return (
            <Button
                className={
                    selectedWorkspace.workspaceName === "" ||
                    selectedSpace.spaceName === ""
                        ? "newFolderD"
                        : "newFolder"
                }
                disabled={
                    selectedWorkspace.workspaceName === "" ||
                    selectedSpace.spaceName === ""
                }
                onClick={handleAddFolderClick}

                // className={classes.button}
            >
                New Folder
            </Button>
        );
    };
    const renderSnackBar = () => {
        return (
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={alertDialogContent.message}
            ></CustomSnackbar>
        );
    };
    return (
        <>
            {addFolderModalShow ? (
                <AddFolderModal
                    showFolderModal={addFolderModalShow}
                    closeModal={closeAddFolderClick}
                    setText={setText}
                    addNewFolder={addNewFolder}
                    error={error}
                />
            ) : (
                <div style={{ minWidth: "300px" }} className={"demo"}>
                    <div className={classes.root}>
                        <div
                            className="row w-100 m-0"
                            style={{ alignItems: "center" }}
                        >
                            <div className="row m-0 w-100">
                                <div
                                    className="col-11 pl-0"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontWeight: "600",
                                    }}
                                >
                                    <div style={{ paddingLeft: "25px" }}>
                                        {(curruntAction === "edit" &&
                                            activeStep === 1) ||
                                        activeStep === 2 ? (
                                            <div>Confirm</div>
                                        ) : curruntAction === "edit" ? (
                                            <div>Edit Step</div>
                                        ) : (
                                            <div>Create Step</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-1 p-0 doCenter">
                                    <button
                                        type="button"
                                        onClick={handleReset}
                                        class="close modalCloseIcon"
                                        data-dismiss="modal"
                                        id={"closeModalButton"}
                                    >
                                        &times;
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* {defaultStep === 1 ? null : (
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>
                                                {label}
                                            </StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        )} */}
                        <div
                            className="formContainer invisibleScroller"
                            style={{ paddingRight: "12px" }}
                        >
                            {activeStep === steps.length ? (
                                <div>
                                    <Typography
                                        className={classes.instructions}
                                    >
                                        <div
                                            className="marginTopTen doCenter"
                                            style={{
                                                fontWeight: "bold",
                                                margin: "50px",
                                            }}
                                        >
                                            {getUpdateStatus()}
                                        </div>
                                    </Typography>
                                </div>
                            ) : (
                                <div className="formContainerBody">
                                    <Typography
                                        className={classes.instructions}
                                    >
                                        {defaultStep
                                            ? renderDropdowns()
                                            : getStepContent(
                                                  activeStep,
                                                  curruntAction
                                              )}
                                    </Typography>
                                    {errorMsg !== "" ? (
                                        <span className="w-100 doCenter err_msg">
                                            {errorMsg}
                                        </span>
                                    ) : null}
                                    <div
                                        className="marginTopTen "
                                        style={
                                            activeStep === 1
                                                ? {
                                                      margin: "15px 0px",
                                                      justifyContent: "end",
                                                      float: "right",
                                                  }
                                                : {
                                                      margin: "16px",
                                                      float: "right",
                                                  }
                                        }
                                    >
                                        {/* {activeStep === 1 &&
                                        curruntAction !== "edit"
                                            ? NewFolder()
                                            : null} */}
                                        <Button
                                            id="btn_addStep_back"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className="set_cancel"
                                            style={{
                                                boxShadow: "none",
                                                backgroundColor: "white",
                                            }}
                                            variant={
                                                defaultStep
                                                    ? "outlined"
                                                    : "contained"
                                            }
                                        >
                                            {defaultStep ? "Cancel" : "Back"}
                                        </Button>
                                        <Button
                                            id={
                                                activeStep === steps.length - 1
                                                    ? "btn_addStep_yes"
                                                    : "btn_addStep"
                                            }
                                            variant="contained"
                                            color="primary"
                                            onClick={
                                                (activeStep === 1 &&
                                                    curruntAction === "edit") ||
                                                activeStep === 1
                                                    ? handleChangeFolderId
                                                    : handleNext
                                            }
                                            // className={classes.button}
                                            className="bw_btn"
                                            disabled={isButtonDisable()}
                                            style={{
                                                margin: "5px 20px",
                                                minWidth: "5rem",
                                            }}
                                        >
                                            {activeStep === steps.length - 1
                                                ? "Submit"
                                                : getButtonName()}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {renderSnackBar()}
                </div>
            )}

            {openSecondModal && (
                <StorageModal
                    selectedWorkspace={selectedWorkspace}
                    selectedSpace={selectedSpace}
                    selectedFolder={selectedFolder}
                    setSelectedSpace={setSelectedSpace}
                    setSelectedWorkspace={setSelectedWorkspace}
                    setSelectedFolder={setSelectedFolder}
                    renderDropdowns={renderDropdowns}
                    handleChangeStorage={handleChangeStorage}
                    open={openSecondModal}
                    handleAddFolderClick={handleAddFolderClick}
                    handleNext={handleNext}
                    handleClose={() => setOpenSecondModal(false)}
                />
            )}

            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
                setOpen={changeOpenDialogFlag}
            ></AlertDialog>
            <Loader show={showLoader} />
        </>
    );
}

const mapDispatchToProps = () => {
    return {
        // setActiveSpace,
        // setActiveWorkspace,
        // addSpace,
        // fetchSpaceListData,
        // setBoardList
        // fetchFolderListData,
        // resetFolderListData,
        fetchWorkspaceListData,
    };
};

const mapStateToProps = (state) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps())(AddStepStepper);

import React, { useState, useCallback, useEffect } from "react";
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    removeElements,
    isNode,
    Controls,
} from "react-flow-renderer";
import { useSelector, useDispatch } from "react-redux";
import dagre from "dagre";
import BranchViewReactFlow from "./BranchViewReactFlow";
import DocumentViewerModal from "../../../../../stylized/DocumentViewerModal";
import DocViwer from "../../../../../stylized/DocViwer";
import { showAlert } from "../../../../../../actions/AlertView";
import CustomNode from "./CustomNode";

const BranchView = ({
    selectedInstanceSteps,
    selectedGenericSteps,
    selectedMergeBlocks,
    branchViewData,
}) => {
    const nodeTypes = {
        selectorNode: (nodeProps) => (
            <CustomNode
                {...nodeProps}
                selectedInstanceSteps={selectedInstanceSteps}
            />
        ),
    };
    const [ele, setEle] = useState([]);
    const [dataError, setDataError] = useState(null);
    const [elements, setElements] = useState(ele);
    const alertView = useSelector((state) => state.alertView);
    const dispatch = useDispatch();
    const [documentModalShow, setDocumentModalShow] = React.useState(false);
    const [currentShowFileName, setCurrentShowFileName] = React.useState("");
    const [currentShowFileLocation, setCurrentShowFileLocation] =
        React.useState("");

    useEffect(() => {
        if (branchViewData) {
            let selectedInstanceStepsWithoutDuplicates =
                branchViewData.selectedInstanceSteps.map((instanceStep) => {
                    return { ...instanceStep };
                });
            selectedInstanceStepsWithoutDuplicates.forEach(
                (selectedInstanceStep, index) => {
                    if (selectedInstanceStep.durationStatus === "SKIPPED") {
                        let duplicateStep =
                            branchViewData.originalInstanceSteps.filter(
                                (originalInstanceStep) =>
                                    selectedInstanceStep.instanceStepName ===
                                        originalInstanceStep.instanceStepName &&
                                    selectedInstanceStep.branchId ===
                                        originalInstanceStep.branchId &&
                                    selectedInstanceStep.durationStatus !==
                                        originalInstanceStep.durationStatus
                            );

                        if (duplicateStep.length) {
                            let propsToReplace = {
                                durationStatus:
                                    duplicateStep[duplicateStep.length - 1]
                                        .durationStatus,
                                instanceBlockDetails:
                                    duplicateStep[duplicateStep.length - 1]
                                        .instanceBlockDetails,
                                instanceDuplicateStepId:
                                    duplicateStep[duplicateStep.length - 1]
                                        .instanceStepId,
                            };
                            Object.assign(
                                selectedInstanceStepsWithoutDuplicates[index],
                                propsToReplace
                            );
                            for (let i = 0; i < duplicateStep.length; i++) {
                                const index =
                                    selectedInstanceStepsWithoutDuplicates
                                        .map((step) => step.instanceStepId)
                                        .indexOf(
                                            duplicateStep[i].instanceStepId
                                        );
                                if (index > -1)
                                    selectedInstanceStepsWithoutDuplicates.splice(
                                        index,
                                        1
                                    );
                            }
                        }
                    }
                }
            );
            setElements(
                getLayoutedElements(
                    BranchViewReactFlow(
                        branchViewData
                            ? selectedInstanceStepsWithoutDuplicates
                            : [],
                        selectedGenericSteps,
                        branchViewData
                            ? branchViewData.selectedMergeBlocks
                            : [],
                        setDataError,
                        branchViewData
                            ? branchViewData.originalInstanceSteps
                            : []
                    )
                )
            );
        } else setElements([]);
    }, [branchViewData]);

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    const nodeWidth = 600;
    const nodeHeight = 150;

    const getLayoutedElements = (elements, direction = "LR") => {
        const isHorizontal = direction === "LR";
        dagreGraph.setGraph({ rankdir: direction });

        elements.forEach((el) => {
            if (isNode(el)) {
                dagreGraph.setNode(el.id, {
                    width: nodeWidth,
                    height: nodeHeight,
                });
            } else {
                dagreGraph.setEdge(el.source, el.target);
            }
        });

        dagre.layout(dagreGraph);

        return elements.map((el) => {
            if (isNode(el)) {
                const nodeWithPosition = dagreGraph.node(el.id);
                el.targetPosition = isHorizontal ? "left" : "top";
                el.sourcePosition = isHorizontal ? "right" : "bottom";
                el.position = {
                    x:
                        nodeWithPosition.x -
                        nodeWidth / 2 +
                        Math.random() / 1000,
                    y: nodeWithPosition.y - nodeHeight / 2,
                };
            }

            return el;
        });
    };
    // const layoutedElements = getLayoutedElements(
    //     BranchViewReactFlow(ele || null)
    // );
    const graphStyles = { width: "100%", height: "90%", padding: "10px" };
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const onConnect = (params) =>
        setElements((els) =>
            addEdge({ ...params, type: "default", animated: true }, els)
        );
    const onElementsRemove = (elementsToRemove) =>
        setElements((els) => removeElements(elementsToRemove, els));
    const onLayout = useCallback(
        (direction) => {
            const layoutedElements = getLayoutedElements(elements, direction);
            setElements(layoutedElements);
        },
        [elements]
    );

    const onClickElement = (e, node) => {};
    const handleOnLoad = (instance) => {
        setReactFlowInstance(instance);
    };

    const handleDocumentModalClose = () => {
        setDocumentModalShow(false);
        setCurrentShowFileLocation("");
        setCurrentShowFileName("");
        dispatch(showAlert(null));
    };

    useEffect(() => {
        if (alertView) {
            setDocumentModalShow(alertView.documentModalShow);
            setCurrentShowFileName(alertView.currentShowFileName);
            setCurrentShowFileLocation(alertView.currentShowFileLocation);
        }
    }, [alertView]);

    const renderViewDocumentModal = (event) => {
        return (
            <DocViwer
                docName={currentShowFileName}
                fileLocation={currentShowFileLocation}
            ></DocViwer>
        );
    };

    return (
        <div className="layoutflow w-100 h-100" style={{ margin: "10px" }}>
            <>
                {dataError ? (
                    <div className="alert alert-danger">
                        {"it seems the data is invalid!"}
                    </div>
                ) : null}
                <ReactFlow
                    elements={true ? elements : []}
                    style={graphStyles}
                    onConnect={onConnect}
                    onNodeDoubleClick={onClickElement}
                    onElementsRemove={onElementsRemove}
                    onLoad={handleOnLoad}
                    nodeTypes={nodeTypes}
                    connectionLineType="smoothstep"
                    nodesDraggable={true}
                    defaultZoom={elements.length > 15 ? 0.3 : 0.5}
                    minZoom={elements.length > 15 ? 0.1 : 0.3}
                >
                    <Controls
                        style={{
                            border: "1px solid black",
                            marginLeft: "-10px",
                            marginBottom: "90px",
                        }}
                        showFitView={true}
                        showInteractive={false}
                    ></Controls>
                </ReactFlow>
                {/* <div>
                    <button
                        id="btn_Ripple_verticalLayout"
                        onClick={() => onLayout("TB")}
                    >
                        Vertical Layout
                    </button>

                    <button
                        id="btn_Ripple_horizontalLayout"
                        onClick={() => onLayout("LR")}
                    >
                        Horizontal Layout
                    </button>
                </div> */}
                <DocumentViewerModal
                    showHeader={true}
                    footer="false"
                    show={documentModalShow}
                    size="lg"
                    onHide={handleDocumentModalClose}
                    title=""
                    modalBodyClass="documentViewModal"
                    onclick={() => {}}
                    submitButtonText="Close"
                    headerTitle={currentShowFileName}
                    fileSource={currentShowFileLocation}
                >
                    {documentModalShow ? renderViewDocumentModal() : null}
                </DocumentViewerModal>
            </>
        </div>
    );
};

export default BranchView;

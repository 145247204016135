import { selectLocalVideoTrackID } from "@100mslive/hms-video-store";
import React, { useEffect, useState } from "react";
import { setSelectedFolderArray } from "../../../../../../actions/Rippleaction";
import http from "../../../../../../bae-client/HTTP";
import Loader from "../../../../../stylized/Loader";
import Loading from "../../../../../stylized/Loading";

const DataComponent = ({ data }) => {
    const [resourceList, setResourceList] = useState([]);
    const [alertsLoading, setAlertsLoading] = useState(true);
    const [showLoader, setShowLoader] = React.useState(false);
    useEffect(() => {
        setShowLoader(true);
        let url = "home/resources";
        http.sendRestRequest(url, "GET")
            .then((response) => {
                setResourceList(response);
                setShowLoader(false);
                setAlertsLoading(false);
            })
            .catch((err) => {
                setShowLoader(false);
                setAlertsLoading(false);
                console.log(err);
            });
    }, []);
    return (
        <>
            {alertsLoading ? (
                <Loading />
            ) : (
                <div className="resources invisibleScroller">
                    <div className="heading">Resources</div>
                    {resourceList.map((item, index) => (
                        <div>
                            <div key={index} style={{ marginTop: "25px" }}>
                                {item.resourceText ? (
                                    <h2 className="resourceTitle">
                                        {item.resourceText}
                                    </h2>
                                ) : (
                                    <h2 className="resourceTitle">
                                        {item.resourceName}
                                    </h2>
                                )}
                                <a
                                    href={item.resourceURL}
                                    target="_blank"
                                    className="resourceContent"
                                >
                                    {item.resourceURL}
                                </a>
                            </div>
                            <hr />
                        </div>
                    ))}
                </div>
            )}
            {/* <Loader show={showLoader}></Loader> */}
        </>
    );
};

export default DataComponent;

import { combineReducers } from "redux";
import { linkImport, refresh } from "./WebGridReducer";
import {
    workspaceList,
    addWorkspace,
    workspaceIdToName,
    workspaceNameToId,
} from "./WorkspaceReducers";
import { folderListData, folderIdToName } from "./FolderReducer";
import {
    DocumentChainReducer,
    selectedFlows,
    selectedExceptions,
    allInstanceWithFilter,
    selectedFlowIds,
    selectedExceptionIds,
    selectedTimeFilterGlobal,
    selectedAlertIds,
    selectedTimeFilterGlobalAlert,
    selectedFlowIdsAlert,
    selectedFilterOptions,
    selectedExceptionIdsAlert,
    allInstanceWithFilterAlert,
    selectedFinalFilter,
    alertLogData,
    homeAlertLogData,
} from "./DocumentChainReducer";
import {
    spaceListData,
    newSpace,
    spaceIdToName,
    spaceNameToId,
    spaceIdToWorkspaceId,
} from "./SpaceReducer";
import { cuboidInfoByName, cuboidInfoById } from "./CuboidInfoReducer";
import { hierarchyResponse } from "./HierarchyReducer";
import { rippleResult } from "./RippleReducer";
import {
    disableRefreshFeed,
    informationFeedItems,
    existingIntegrationList,
    informationFeedItemToRender,
    newMailNotification,
} from "./InformationFeed";
import { webSocketNotification } from "./WebSocketReducer";
import {
    chatList,
    activeChatUser,
    sharedData,
    unreadChatCount,
} from "./CommunicatorReducer";
import { userIdToFullName, userIdToEmail } from "./UserReducer";
import { filterList } from "./FilterListReducer";

import { alertView } from "./AlertView";
import {
    selectedGenericIndexesState,
    aggregatedReportData,
    InstanceList,
} from "./documentChain";
import {
    DashboardData,
    DashboardDCFilterData,
    InstanceData,
    genericIdToNameMap,
    selectedGenericDropdownValuesAggrReport,
    selectedDropdownModelIndex,
    selectedInstanceModelIndex,
    selectedTransactionModelIndex,
    selectedFromModelIndex,
    selectedToModelIndex,
    selectedPromiceModelIndex,
    selectedExceptionModelIndex,
    selectedExceptionModelStatus,
    selectedTimeModelIndex,
    dashboardAlerts,
    searchDashboardText,
    dashboardAlertData,
    existingGenericData,
    genericNameToIdMap,
    genericIdToDisplayNameMap,
    instanceDataByGenericId,
    selectedGenericDropdownValue,
    selectedGenericAttachFlowDropdownValue,
    selectedInstanceDropdownValue,
    selectedTransactionDropdownValue,
    selectedPromiseDropdownValue,
    selectedExceptionDropdownValue,
    selectedExceptionStatusDropdownValue,
    selectedToDropdownValue,
    selectedFromDropdownValue,
    selectedTimeDropdownValue,
    selectedGenericDropdownValueFilterOne,
    selectedInstanceDropdownValueFilterOne,
    selectedTransactionDropdownValueFilterOne,
    selectedPromiseDropdownValueFilterOne,
    selectedExceptionDropdownValueFilterOne,
    selectedExceptionStatusDropdownValueFilterOne,
    selectedToDropdownValueFilterOne,
    selectedFromDropdownValueFilterOne,
    selectedTimeDropdownValueFilterOne,
    dashboardSearchTextFilterOne,
    selectedGenericDropdownValueFilterTwo,
    selectedInstanceDropdownValueFilterTwo,
    selectedTransactionDropdownValueFilterTwo,
    selectedPromiseDropdownValueFilterTwo,
    selectedExceptionDropdownValueFilterTwo,
    selectedExceptionStatusDropdownValueFilterTwo,
    selectedToDropdownValueFilterTwo,
    selectedFromDropdownValueFilterTwo,
    selectedTimeDropdownValueFilterTwo,
    dashboardSearchTextFilterTwo,
} from "./documentChain";
import {
    directoryList,
    directoryUsersList,
} from "../components/functional/UnityCentral/LeftPane/Directory/DirectoryReducers";
import { unreadCountOfAlerts } from "./AlertReducer";
import {
    activePageReducer,
    activeSpaceReducer,
    activeWorkspaceReducer,
    addWorkspaceModalReducer,
    directoriesReducer,
    directoryUsersReducer,
    emailRecievedReducer,
    modulesReducer,
    snackbarReducer,
    spaceListReducer,
    workspaceListReducer,
    workspaceRedirectionReducer,
    rippleFilterReducer,
} from "../services/services/app";
import { usersAPI } from "../services/services/usersAPI";
const selectedCollabsViewReducer = (collabsView = null, action) => {
    if (action.type === "SET_COLLABS_VIEW") {
        return action.payload;
    }
    return collabsView;
};

const redirectToTrash = (redirectToTrash = false, action) => {
    if (action.type === "SET_REDIRECT_TO_TRASH") {
        return action.payload;
    }
    return redirectToTrash;
};
const selectedCollabReducer = (selectedCollab = null, action) => {
    if (action.type === "SELECT_COLLAB") {
        return action.payload;
    }
    return selectedCollab;
};

const selectedWBReducer = (selectedWB = null, action) => {
    if (action.type === "SELECT_WB") {
        return action.payload;
    }
    return selectedWB;
};

const selectedCuboidReducer = (selectedCuboid = null, action) => {
    if (action.type === "SELECT_CUBOID") {
        return action.payload;
    }
    return selectedCuboid;
};

const filesForUploadReducer = (setFilesForUpload = null, action) => {
    if (action.type === "SET_FILES_FOR_UPLOAD_IN_CANVAS") {
        return action.payload;
    }
    return setFilesForUpload;
};
const selectActiveSpace = (setDocumentsforUpload = null, action) => {
    if (action.type === "SET_ACTIVE_SPACE") {
        return action.payload;
    }
    return setDocumentsforUpload;
};

const selectActiveFolderPath = (setActiveFolderPath = [], action) => {
    if (action.type === "ACTIVE_FOLDER_PATH") {
        return action.payload;
    }
    return setActiveFolderPath;
};
const selectActiveBreadcrumPath = (
    setActiveBreadcrumPath = new Map(),
    action
) => {
    if (action.type === "ACTIVE_BREADCRUM_PATH") {
        return action.payload;
    }
    return setActiveBreadcrumPath;
};
const collabInfoReducer = (collabInfo = null, action) => {
    if (action.type === "SET_COLLAB_INFO") {
        return action.payload;
    }
    return collabInfo;
};

const currentPageReducer = (page = null, action) => {
    if (action.type === "SET_PAGE") {
        return action.payload;
    }
    return page;
};
const currentPagetYPEReducer = (page = null, action) => {
    if (action.type === "SET_PAGE_TYPE") {
        return action.payload;
    }
    return page;
};
const leftPanelReducer = (flag = null, action) => {
    if (action.type === "SET_LEFT_PANEL") {
        return action.payload;
    }
    return flag;
};
const rightPanelReducer = (flag = null, action) => {
    if (action.type === "SET_RIGHT_PANEL") {
        return action.payload;
    }
    return flag;
};
const spaceManagerReducer = (files = null, action) => {
    if (action.type === "SET_SPACE") {
        return action.payload;
    }
    return files;
};
const jiraManagerReducer = (data = null, action) => {
    if (action.type === "SET_JIRA") {
        return action.payload;
    }
    return data;
};
const gmailManagerReducer = (data = null, action) => {
    if (action.type === "SET_GMAIL") {
        return action.payload;
    }
    return data;
};

const alertManagerReducer = (data = null, action) => {
    if (action.type === "SET_ALERT") {
        return action.payload;
    }
    return data;
};

const boardList = (boardList = null, action) => {
    if (action.type === "SET_BOARD_LIST") {
        return action.payload;
    }
    return boardList;
};

const currentSettingsPage = (page = null, action) => {
    if (action.type === "SET_CURRENT_SETTINGS_PAGE") {
        return action.payload;
    }
    return page;
};

const currentAlertsWidget = (widget = null, action) => {
    if (action.type === "SET_ALERT_WIDGET") {
        return action.payload;
    }
    return widget;
};
const splitViewSetter = (splitView = "Split_Canvas_NOW", action) => {
    if (action.type === "SET_SPLIT_VIEW") {
        return action.payload;
    }
    return splitView;
};

const currentActivePage = (activePage = "HOME", action) => {
    if (action.type === "SET_ACTIVE_PAGE") {
        return action.payload;
    }
    return activePage;
};
const currentLoginContent = (loginContent = "login", action) => {
    if (action.type === "SET_LOGIN_CONTENT") {
        return action.payload;
    }
    return loginContent;
};
const alertData = (alertData = null, action) => {
    if (action.type === "SET_ALERT_DATA") {
        return action.payload !== null ? [...action.payload] : null;
    }
    return alertData;
};
const selectedAlertIndex = (alertindex = null, action) => {
    if (action.type === "SET_SELECTED_ALERT_INDEX") {
        return action.payload;
    }
    return alertindex;
};
const currentClickedSpaceIndex = (spaceIndex = null, action) => {
    if (action.type === "SET_CURRENT_CLICKED_SPACE_INDEX") {
        return action.payload;
    }
    return spaceIndex;
};
const workspaceManager = (workspaceManager = null, action) => {
    if (action.type === "SET_WORKSPACE_MANAGER") {
        return action.payload;
    }
    return workspaceManager;
};
const activeWorkspace = (activeWorkspace = [], action) => {
    if (action.type === "SET_ACTIVE_WORKSPACE") {
        return action.payload;
    }
    return activeWorkspace;
};

const activeFolder = (activeFolder = null, action) => {
    if (action.type === "SET_ACTIVE_FOLDER") {
        return action.payload;
    }
    return activeFolder;
};

const activeRipple = (activeRipple = [], action) => {
    if (action.type === "SET_ACTIVE_RIPPLE") {
        return action.payload;
    }
    return activeRipple;
};
const errRipple = (errRipple = "", action) => {
    if (action.type === "SET_CHAT_RIPPLE_MSG") {
        return action.payload;
    }
    return errRipple;
};
const showDrawer = (showDrawer = false, action) => {
    if (action.type === "SET_DRAWER") {
        return action.payload;
    }
    return showDrawer;
};
const selectedNextDoc = (selectedNextDoc = [], action) => {
    if (action.type === "SET_NEXT_DOC") {
        return action.payload;
    }
    return selectedNextDoc;
};
const chipsArray = (chipsArray = [], action) => {
    if (action.type === "SET_CHIP_ARRAY") {
        return action.payload;
    }
    return chipsArray;
};
const redirectToRipplelog = (redirectToRipplelog = false, action) => {
    if (action.type === "SET_REDIRECT_TO_RIPPLELOG") {
        return action.payload;
    }
    return redirectToRipplelog;
};
const instanceBranch = (instanceBranch = false, action) => {
    if (action.type === "SET_UPDATED_INSTANCE_BRANCH") {
        return action.payload;
    }
    return instanceBranch;
};
const selectedWorkspaceIndex = (selectedWorkspaceIndex = null, action) => {
    if (action.type === "SET_SELECTED_WORKSPACE_INDEX") {
        return action.payload;
    }
    return selectedWorkspaceIndex;
};
const selectedWorkspaceArray = (selectedWorkspaceArray = [], action) => {
    if (action.type === "SET_SELECTED_WORKSPACE_ARRAY") {
        return action.payload;
    }
    return selectedWorkspaceArray;
};
const selectedDateArray = (selectedDateArray = [], action) => {
    if (action.type === "SET_SELECTED_DATE_ARRAY") {
        return action.payload;
    }
    return selectedDateArray;
};
const selectedFolderArray = (selectedFolderArray = [], action) => {
    if (action.type === "SET_SELECTED_FOLDER_ARRAY") {
        return action.payload;
    }
    return selectedFolderArray;
};
const selectedSpaceArray = (selectedSpaceArray = [], action) => {
    if (action.type === "SET_SELECTED_SPACE_ARRAY") {
        return action.payload;
    }

    return selectedSpaceArray;
};
const selectedSpaceIndex = (selectedSpaceIndex = null, action) => {
    if (action.type === "SET_SELECTED_SPACE_INDEX") {
        return action.payload;
    }
    return selectedSpaceIndex;
};
const selectedFolderIndex = (selectedFolderIndex = null, action) => {
    if (action.type === "SET_SELECTED_FOLDER_INDEX") {
        return action.payload;
    }
    return selectedFolderIndex;
};

const selectedStartDate = (selectedStartDate = "", action) => {
    if (action.type === "SET_SELECTED_START_DATE") {
        return action.payload;
    }
    return selectedStartDate;
};
const spaceArray = (spaceArray = [], action) => {
    if (action.type === "SET_SPACE_ARRAY") {
        return action.payload;
    }
    return spaceArray;
};
const showValue = (showValue = "", action) => {
    if (action.type === "SET_VALUE") {
        return action.payload;
    }
    return showValue;
};

const selectedEndDate = (selectedEndDate = "", action) => {
    if (action.type === "SET_SELECTED_END_DATE") {
        return action.payload;
    }
    return selectedEndDate;
};
const rippleNameStr = (rippleNameStr = "", action) => {
    if (action.type === "SET_RIPPLE_NAME_STR") {
        return action.payload;
    }
    return rippleNameStr;
};
const selectedPeriod = (selectedPeriod = "", action) => {
    if (action.type === "SET_SELECTED_PERIOD") {
        return action.payload;
    }
    return selectedPeriod;
};
const initialState = {
    startDate: "",
    endDate: "",
    selectedTimeType: "",
};
const selectedTimePeriodReducer = (state = initialState, action) => {
    if (action.type === "SET_SELECTED_TIME_PERIOD") {
        return {
            ...state,
            startDate: action.payload.startDate,
            endDate: action.payload.endDate,
            selectedTimeType: action.payload.selectedTimeType,
        };
    }
    return state;
};

const rippleDocumentResponse = (rippleDocumentResponse = null, action) => {
    if (action.type === "SET_RIPPLE_DOCUMENT_RESPONSE") {
        return action.payload;
    }
    return rippleDocumentResponse;
};
const rippleId = (rippleId = "", action) => {
    if (action.type === "SET_RIPPLE_ID") {
        return action.payload;
    }
    return rippleId;
};
const refreshLocation = (locationData = null, action) => {
    if (action.type === "SET_LOCATION_DATA") {
        return action.payload;
    }
    return locationData;
};
const membersRefreshAction = (locationData = null, action) => {
    if (action.type === "SET_MEMBERS_REDIRECTION") {
        return action.payload;
    }
    return locationData;
};
const rippleDeleteId = (rippleDeleteId = "", action) => {
    if (action.type === "SET_DELETE_RIPPLE_ID") {
        return action.payload;
    }
    return rippleDeleteId;
};
const rippleKeywordSearched = (rippleKeywordSearched = false, action) => {
    if (action.type === "SET_RIPPLE_KEYWORD_SEARCHED") {
        return action.payload;
    }
    return rippleKeywordSearched;
};
const timelineDocumentList = (timelineDocumentList = [], action) => {
    if (action.type === "SET_TIMELINE_DOCUMENT_LIST") {
        return action.payload;
    }
    return timelineDocumentList;
};
const workspaceTreeStructure = (wsStructure = null, action) => {
    if (action.type === "SET_WS_TREE_SSTRUCTURE") {
        return action.payload;
    }
    return wsStructure;
};
const workspaceFetcher = (wsFetcher = null, action) => {
    if (action.type === "SET_WS_FETCHER") {
        return action.payload;
    }
    return wsFetcher;
};

const archiveInfo = (
    archiveInfo = { alertInfo: [], spaceInfo: [] },
    action
) => {
    if (action.type === "SET_ARCHIVE_INFO") {
        return action.payload;
    } else if (action.type === "UPDATE_ARCHIVE_INFO_ALERT") {
        archiveInfo.alertInfo.push(action.payload);
        return { ...archiveInfo };
    }
    return archiveInfo;
};
const informationFeedData = (informationFeedData = null, action) => {
    if (action.type === "SET_INFORMATION_FEED_DATA") {
        return action.payload;
    }
    return informationFeedData;
};
const controlTowerData = (controlTowerData = null, action) => {
    if (action.type === "SET_CONTROL_TOWER_DATA") {
        return action.payload;
    }
    return controlTowerData;
};
const documentHierarchyInfo = (documentHierarchy = null, action) => {
    if (action.type === "SET_DOCUMENT_HIERARCHY_INFO") {
        return action.payload;
    }
    return documentHierarchy;
};
const genericInfo = (genericInfo = null, action) => {
    if (action.type === "SET_GENERIC_INFO") {
        return action.payload;
    }
    return genericInfo;
};
const documentMetaData = (documentMetaData = null, action) => {
    if (action.type === "SET_DOCUMENT_META_DATA") {
        return action.payload;
    }
    return documentMetaData;
};
const blockchainResponseData = (blockchainResponse = null, action) => {
    if (action.type === "SET_BLOCKCHAIN_RESPONSE") {
        return action.payload;
    }
    return blockchainResponse;
};

const bwModulesResponseData = (bwModulesResponse = false, action) => {
    if (action.type === "SET_BW_MODULES_OF_LEFTPANE") {
        return action.payload;
    }
    return bwModulesResponse;
};

const integrations = (
    integrations = { integrations: [], expiredRefreshTokensIntegrations: [] },
    action
) => {
    if (action.type === "LOAD_INTEGRATIONS") {
        return action.payload;
    }
    return integrations;
};
const worksapceInfoRedirection = (payload = null, action) => {
    if (action.type === "WORKSPACE_INFO_REDIRECTION") return action.payload;
    return payload;
};

const homeRedirection = (payload = false, action) => {
    if (action.type === "HOME_REDIRECTION") return action.payload;
    return payload;
};
const addWorkspaceShowModal = (payload = false, action) => {
    if (action.type === "ADD_WORKSPACE_SHOW_MODAL") return action.payload;
    return payload;
};
const appReducer = combineReducers({
    worksapceInfoRedirection,
    showCollabs: selectedCollabsViewReducer,
    currentPage: currentPageReducer,
    selectedTimePeriodReducer: selectedTimePeriodReducer,
    currentPageType: currentPagetYPEReducer,
    selectedCollab: selectedCollabReducer,
    redirectToTrash: redirectToTrash,
    selectedWB: selectedWBReducer,
    selectedCuboid: selectedCuboidReducer,
    breadcrumPath: selectActiveBreadcrumPath,
    activeBoard: filesForUploadReducer,
    // activeSpace: selectActiveSpace,
    activeFolderPath: selectActiveFolderPath,
    collabInfo: collabInfoReducer,
    leftPanel: leftPanelReducer,
    rightPanel: rightPanelReducer,
    spaceManager: spaceManagerReducer,
    jiraManager: jiraManagerReducer,
    gmailManager: gmailManagerReducer,
    alertManager: alertManagerReducer,
    boardList: boardList,
    settingsPage: currentSettingsPage,
    alertWidget: currentAlertsWidget,
    splitView: splitViewSetter,
    // activePage: currentActivePage,
    loginContent: currentLoginContent,
    alertData: alertData,
    selectedAlertIndex: selectedAlertIndex,
    currentClickedSpaceIndex: currentClickedSpaceIndex,
    workspaceManager: workspaceManager,
    // activeWorkspace: activeWorkspace,
    activeFolder: activeFolder,
    activeRipple: activeRipple,
    errRipple: errRipple,
    showDrawer: showDrawer,
    chipsArray: chipsArray,
    redirectToRipplelog: redirectToRipplelog,
    instanceBranch: instanceBranch,
    selectedNextDoc: selectedNextDoc,
    spaceArray: spaceArray,
    showValue: showValue,
    selectedWorkspaceIndex: selectedWorkspaceIndex,
    selectedWorkspaceArray: selectedWorkspaceArray,
    selectedDateArray: selectedDateArray,
    selectedFolderArray: selectedFolderArray,
    selectedSpaceArray: selectedSpaceArray,
    selectedFolderIndex: selectedFolderIndex,
    selectedSpaceIndex: selectedSpaceIndex,
    selectedStartDate: selectedStartDate,
    selectedEndDate: selectedEndDate,
    rippleNameStr: rippleNameStr,
    rippleId: rippleId,
    membersRefreshAction: membersRefreshAction,
    refreshLocation: refreshLocation,
    rippleDeleteId: rippleDeleteId,
    selectedPeriod: selectedPeriod,
    timelineDocumentList: timelineDocumentList,
    rippleKeywordSearched: rippleKeywordSearched,
    rippleDocumentResponse: rippleDocumentResponse,
    workspaceTreeStructure: workspaceTreeStructure,
    workspaceFetcher: workspaceFetcher,
    archiveInfo: archiveInfo,
    informationFeedData: informationFeedData,
    controlTowerData: controlTowerData,
    documentHierarchyInfo: documentHierarchyInfo,
    genericInfo: genericInfo,
    documentMetaData: documentMetaData,
    blockchainResponse: blockchainResponseData,
    bwModulesResponse: bwModulesResponseData,
    linkImport,
    refresh,
    directoryList: directoryList,
    directoryUsersList: directoryUsersList,
    unreadCountOfAlerts: unreadCountOfAlerts,
    // workspaceList: workspaceList,
    // spaceListData: spaceListData,
    folderListData: folderListData,
    informationFeedItemToRender,
    existingIntegrationList,
    addWorkspace: addWorkspace,
    cuboidInfoByName,
    cuboidInfoById,
    hierarchyResponse,
    rippleResult,
    DocumentChainReducer,
    selectedAlertIds,
    selectedTimeFilterGlobalAlert,
    selectedFlowIdsAlert,
    selectedFilterOptions,
    selectedExceptionIdsAlert,
    allInstanceWithFilterAlert,
    selectedFinalFilter,
    selectedFlows,
    selectedExceptions,
    allInstanceWithFilter,
    selectedFlowIds,
    selectedExceptionIds,
    selectedTimeFilterGlobal,
    chatList,
    activeChatUser,
    unreadChatCount,
    sharedData,
    newSpace,
    disableRefreshFeed,
    webSocketNotification,
    workspaceIdToName,
    workspaceNameToId,
    spaceIdToName,
    spaceNameToId,
    userIdToFullName,
    userIdToEmail,
    spaceIdToWorkspaceId,
    folderIdToName,
    filterList,
    informationFeedItems,
    DashboardData,
    DashboardDCFilterData,
    InstanceData,
    dashboardAlerts,
    genericIdToNameMap,
    selectedGenericDropdownValuesAggrReport,
    selectedDropdownModelIndex,
    selectedInstanceModelIndex,
    selectedTransactionModelIndex,
    selectedFromModelIndex,
    selectedToModelIndex,
    selectedPromiceModelIndex,
    selectedExceptionModelIndex,
    selectedExceptionModelStatus,
    selectedTimeModelIndex,
    searchDashboardText,
    dashboardAlertData,
    existingGenericData,
    genericNameToIdMap,
    instanceDataByGenericId,
    newMailNotification,
    alertView,
    aggregatedReportData,
    selectedGenericIndexesState,
    genericIdToDisplayNameMap,
    integrations,
    selectedGenericDropdownValue,
    selectedGenericAttachFlowDropdownValue,
    selectedInstanceDropdownValue,
    selectedTransactionDropdownValue,
    selectedPromiseDropdownValue,
    selectedExceptionDropdownValue,
    selectedExceptionStatusDropdownValue,
    selectedToDropdownValue,
    selectedFromDropdownValue,
    selectedTimeDropdownValue,
    selectedGenericDropdownValueFilterOne,
    selectedInstanceDropdownValueFilterOne,
    selectedTransactionDropdownValueFilterOne,
    selectedPromiseDropdownValueFilterOne,
    selectedExceptionDropdownValueFilterOne,
    selectedExceptionStatusDropdownValueFilterOne,
    selectedToDropdownValueFilterOne,
    selectedFromDropdownValueFilterOne,
    selectedTimeDropdownValueFilterOne,
    dashboardSearchTextFilterOne,
    selectedGenericDropdownValueFilterTwo,
    selectedInstanceDropdownValueFilterTwo,
    selectedTransactionDropdownValueFilterTwo,
    selectedPromiseDropdownValueFilterTwo,
    selectedExceptionDropdownValueFilterTwo,
    selectedExceptionStatusDropdownValueFilterTwo,
    selectedToDropdownValueFilterTwo,
    selectedFromDropdownValueFilterTwo,
    selectedTimeDropdownValueFilterTwo,
    dashboardSearchTextFilterTwo,
    homeRedirection,
    addWorkspaceShowModal,
    activeSpace: activeSpaceReducer,
    modules: modulesReducer,
    activePage: activePageReducer,
    activeWorkspace: activeWorkspaceReducer,
    spaceListData: spaceListReducer,
    addWorkspaceModal: addWorkspaceModalReducer,
    workspaceList: workspaceListReducer,
    snackbar: snackbarReducer,
    directories: directoriesReducer,
    directoryUsers: directoryUsersReducer,
    Users: usersAPI.reducer,
    redirectionObject: workspaceRedirectionReducer,
    emailRecieved: emailRecievedReducer,
    rippleFilterData: rippleFilterReducer,
    alertLogData,
    homeAlertLogData,
    InstanceList,
});

const rootReducer = (state, action) => {
    if (action.type === "RESET_STORE") {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;

import { TablePagination } from "@material-ui/core";
import React, { Component } from "react";
import Column from "./Column";
import InlineRow from "./InlineRow";
import { getJsDateFromExcel } from "excel-date-to-js";
import { getParsedDateByDateAndTime } from "../../bae-client/Utils";
import "./Archive.css";
import PopoverViewCustom from "./PopoverViewCustom";
import ListView from "./ListView";
import CustomDropdown from "./CustomDropdown";
import CustomSearchDropdown from "./CustomDropDown/index";
import { getIconUrls } from "../functional/UnityCentral/ContentPane/Workspace/constant";
class TrashBinListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startCount: 0,
            lastCount: 10,
            page: 0,
            rowsPerPage: 100,
            selectedFilter: "",
        };
        this.rowData = [];
        this.filterItems = [
            {
                text: "Date",
                value: "date",
                id: "date_filter",
            },
            {
                text: "Type",
                value: "type",
                id: "type_filter",
            },
        ];
    }

    handleRestoreBtnClick = (rowItem, index) => {
        this.props.restoreBtnClicked(rowItem, index);
    };
    handleDeleteBtnClick = (rowItem, index) => {
        this.props.deleteBtnClicked(rowItem, index);
    };

    handleDateFilter = (index, listValue, listText, event) => {
        this.props.sortBtnClicked(listValue.value);
    };
    renderFilterDropdown = () => {
        return (
            <CustomSearchDropdown
                items={this.filterItems}
                label={"Sort By"}
                id={"sortBy"}
                multiSelect={false}
                isSearchable={true}
                isChipView={false}
                handleSelection={this.handleDateFilter}
                dropdownPosition={"trash-bin"}
            ></CustomSearchDropdown>
        );
    };
    renderHeaderBar = () => {
        return (
            <InlineRow classname="doCenter archiveHeaderRow m-0">
                {/* <label className="headerLabel">Sort By</label> */}
                {this.renderFilterDropdown()}

                {/* <button
                    onClick={this.props.restoreAllBtnClicked}
                    className="btn btnLabel doCenter newFontMontserrat restoreAll_btn"
                    style={{ width: "auto" }}
                >
                    RESTORE ALL
                </button> */}
                {/* <Column size="3">
                            <button className="btn btnLabel doCenter newFontMontserrat">
                                TYPE
                            </button>
                        </Column> */}
            </InlineRow>
        );
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };
    renderPagination = () => {
        return (
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={this.rowData.length}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                rowsPerPage={this.state.rowsPerPage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
        );
    };
    parseDateExcelToUTC = (dateValue) => {
        let strDate = "" + dateValue;
        let localDate = getJsDateFromExcel(dateValue).toLocaleDateString(
            "en-US",
            { timeZone: "UTC" }
        );
        //let time = getJsDateFromExcel(dateValue).toLocaleTimeString('en-US', { timeZone: 'UTC', timeSyle: "short" });
        //let temp = time.split(" ")[0].split(":");
        //temp = temp[0] + ":" + temp[1] + " " + time.split(" ")[1];
        //return localDate + " - " + temp;
        return localDate;
    };
    getImageIcon = (type) => {
        // Default to "File" if type is empty or undefined
        const normalizedType = (type || "File").toLowerCase();

        const iconPaths = {
            space: "./images/Space 2.svg",
            folder: "./images/FOLDER.svg",
            file: "./images/basicfile.png",
            alert: "./images/AlertIcon.svg",
            workspace: "./images/Workspace A.svg",
            email: "./images/email.svg",
        };

        // Return the corresponding icon or a default icon
        return iconPaths[normalizedType] || "./images/Workspace A.svg";
    };

    renderCardHeaderRow = () => {
        return (
            <div className="row m-0 w-100 archive_headerRow">
                <div className="col-5">
                    <div className="row p-0 m-0 w-100">
                        <div className="col-2"></div>
                        <div className="col-10">
                            <span className="archive_cardHeader_title">
                                Name
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-4 ml-4">
                    <span className="archive_cardHeader_title">Date Added</span>
                </div>
                <div className="col-3"></div>
            </div>
        );
    };
    renderTitle = (type, headerTitle) => {
        let title = "";
        if (headerTitle === "" || headerTitle === undefined) {
            title = "Unsaved";
        } else if (
            (type.toLowerCase() === "ripple" &&
                headerTitle.split(":")[0] === "") ||
            headerTitle.split(":")[0] === " "
        ) {
            title = "Unsaved : " + headerTitle.split(":")[1];
        } else {
            title = headerTitle;
        }
        return title;
    };
    renderCardRow = () => {
        // let rowData = this.props.rowItems;
        let rowsForLogs = [];
        this.rowData
            .slice(
                this.state.page * this.state.rowsPerPage,
                this.state.page * this.state.rowsPerPage +
                    this.state.rowsPerPage
            )
            .map((rowItem, index) => {
                // this.rowData.forEach((rowItem, index) => {
                let title = this.renderTitle(
                    rowItem.objectType,
                    rowItem.objectName
                );
                rowsForLogs.push(
                    <InlineRow classname="rippleLogRow doCenter trashBinItemRow">
                        <Column size="5" className="p-0 textEllipsis">
                            <div
                                className="p-0 m-0 w-100"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {/* <Column size="2" className="doCenter"> */}
                                <img
                                    alt="type"
                                    className="archiveAlertIcon mr-3"
                                    style={{
                                        borderRadius:
                                            rowItem.objectType.toLowerCase() ===
                                            "workspace"
                                                ? "50%"
                                                : "",
                                        width : "25px"
                                    }}
                                    src={rowItem.objectType === "FILE" ?  getIconUrls('file', rowItem.objectName) : this.getImageIcon(rowItem.objectType)}
                                ></img>
                                {/* </Column>
                                <Column size="10" className="p-0"> */}
                                <label
                                    className="archiveRowTilalert m-0"
                                    title={title}
                                >
                                    {title}
                                </label>
                                {/* </Column> */}
                            </div>
                        </Column>
                        <Column size="4">
                            {rowItem.deletedOn !== "" ? (
                                <label className="archived_date m-0">
                                    {
                                        "Deleted on " +
                                            getParsedDateByDateAndTime(
                                                rowItem.deletedOn
                                            )
                                        // this.parseDateExcelToUTC(
                                        // )
                                    }
                                </label>
                            ) : (
                                <label>{""}</label>
                            )}
                        </Column>
                        <Column size="3 doCenter">
                            <InlineRow noRow={true} classname="doCenter">
                                {/* <Column size="6"> */}
                                {rowItem.deletedOn !== "" ? (
                                    <button
                                        className="btn btnLabel doCenter newFontMontserrat"
                                        id={"btn_trash_restore_item_" + index}
                                        onClick={() =>
                                            this.handleRestoreBtnClick(
                                                rowItem,
                                                index
                                            )
                                        }
                                        title="Restore"
                                    >
                                        RESTORE
                                    </button>
                                ) : (
                                    <label>{""}</label>
                                )}
                                {/* </Column> */}
                                <Column size="6">
                                    {rowItem.deletedOn !== "" ? (
                                        <button
                                            className="btn btnLabel doCenter newFontMontserrat"
                                            id={
                                                "btn_trashBin_delete_" +
                                                title.replace(" ", "")
                                            }
                                            onClick={() =>
                                                this.handleDeleteBtnClick(
                                                    rowItem,
                                                    index
                                                )
                                            }
                                            title="Delete"
                                        >
                                            Delete
                                        </button>
                                    ) : (
                                        <label>{""}</label>
                                    )}
                                </Column>
                            </InlineRow>
                        </Column>
                    </InlineRow>
                );
            });
        return rowsForLogs;
    };
    render() {
        this.rowData = this.props.rowItems;
        return (
            <div className="archive_main_container">
                <div className="archive_header_container">
                    {this.renderHeaderBar()}
                </div>
                <div className="archive_main_data invisibleScroller">
                    <div className="archive_header">
                        {this.renderCardHeaderRow()}
                    </div>
                    <div className="archive_data_container invisibleScroller">
                        {this.renderCardRow()}
                    </div>
                    <div className="archive_pagination">
                        {this.renderPagination()}
                    </div>
                </div>
            </div>
        );
    }
}

export default TrashBinListItem;
